import Button from "common/Button/Button";
import sanitize from "utils/SanitizeHtml";

const LearnMore = ({ labels }) => {
  const redirectTo = () => window.open(labels?.learningCenter?.goToLabelLink);
  return (
    <div className="section-three py-2">
      <div className="header mb-4 py-1">
        {labels?.learningCenter?.learnMore}
      </div>

      <div
        dangerouslySetInnerHTML={sanitize(
          labels?.learningCenter?.additionalResources
        )}
      ></div>

      <Button
        id="goToLabel"
        text={labels?.learningCenter?.goToLabel}
        type="primary"
        wrapperClassName="col-xl-2 col-md-3 py-4"
        click={redirectTo}
      />
    </div>
  );
};

export default LearnMore;
