import { useCallback, useContext, useEffect, useState } from "react";
import { SinglePageLayoutContext } from "layout/SinglePageLayout";
import SelectDropdown from "common/SelectDropdown/SelectDropdown";
import Button from "common/Button/Button";
import InputField from "common/InputField/InputField";
import Datepicker from "common/Datepicker/Datepicker";
import sumBy from "lodash/sumBy";
import dayjs from "dayjs";
import { percentageFormatterForDisplay, scrollToTop } from "utils/Utilities";
import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import { Formik, Form, FieldArray } from "formik";
import validationSchema from "./validationSchema";
import {
  useGetStaticDataQuery,
  usePostValidateBeneficiariesMutation,
} from "service/APIService";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import Spinner from "common/Spinner/Spinner";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";
import { setAnnuityPayloadInStore } from "store/commonSlice";
import { useNavigate } from "react-router-dom";
import labels from "utils/LocalizedStrings";

const date = dayjs();
const AddUpdateBeneficiary = () => {
  const singlePageLayoutContext = useContext(SinglePageLayoutContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: staticData } = useGetStaticDataQuery();
  const [beneficiaryType, setBeneficiaryType] = useState("Person");
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [postValidateBeneficiaries] = usePostValidateBeneficiariesMutation();
  const payloadInStore = useSelector(
    (state) => state?.commonReducer?.annuityPayloadInStore
  );

  const addNewBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      {
        id: new Date().toLocaleTimeString(),
        beneficiaryType: beneficiaryType,
        beneficiaryOrg: "",
        firstName: "",
        middleName: "",
        lastName: "",
        fullName: "",
        taxId: "",
        relationship: beneficiaryType === "Person" ? "Spouse" : "Trust",
        allocPct: 0,
        gender: "M",
        dob: null,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        stateCode: "",
        zipCode: "",
        country: "",
        clicked: false,
      },
    ]);
  };

  const deleteBeneficiary = (index) => {
    const beneficiary = beneficiaries;
    beneficiary.splice(index, 1);
    setBeneficiaries([...beneficiary]);
  };

  const updateClicked = (index) => {
    const beneficiary = beneficiaries;
    beneficiary[index].clicked = !beneficiary[index].clicked;
    setBeneficiaries([...beneficiary]);
  };

  const updateData = (val, index, key) => {
    if (key === "dob") {
      val = val.replaceAll("-", "/");
    }
    const beneficiary = beneficiaries;
    beneficiary[index][key] = val;
    setBeneficiaries([...beneficiary]);
  };

  const validateForm = () => {
    const errors = {};

    beneficiaries.forEach(async (e, index) => {
      const error = {};
      try {
        await validationSchema.validate(e, { abortEarly: false });
      } catch (err) {
        err.inner.forEach((e) => {
          error[e.path] = e.message;
          return;
        });
      }

      if (Object.keys(error).length > 0) {
        errors.beneficiaries = errors.beneficiaries || [];
        errors.beneficiaries[index] = error;
      }
    });

    return errors;
  };

  const errorAndTouchedProps = useCallback(
    (errors, touched, setFieldTouched, index, key) => {
      return {
        id: `beneficiaries.${index}.${key}`,
        name: `beneficiaries.${index}.${key}`,
        blur: (val) => {
          updateData(val, index, key);
          setFieldTouched(`beneficiaries.${index}.${key}`, true);
        },
        error:
          errors.beneficiaries && errors.beneficiaries[index]
            ? errors.beneficiaries[index][key]
            : "",
        touched:
          touched.beneficiaries && touched.beneficiaries[index]
            ? touched.beneficiaries[index][key]
            : "",
      };
    },
    [beneficiaries]
  );

  const getTotalAllocPct = () => {
    const total = sumBy(beneficiaries, (e) => parseFloat(e.allocPct));
    setTotalPercentage(total.toFixed(2));
  };

  const validateBeneficiaries = () => {
    setLoading(true);
    setErrors(null);

    let beneficiariesPayload = cloneDeep(beneficiaries);
    for (let i = 0; i < beneficiariesPayload.length; i++) {
      if (!beneficiariesPayload[i].allocPct) {
        setErrors([
          {
            errorMessage:
              "Beneficiary allocation percentage must be greater than 0",
          },
        ]);
        setLoading(false);
        return;
      }
      beneficiariesPayload[i].allocPct =
        parseFloat(beneficiariesPayload[i].allocPct) / 100;
    }

    if (beneficiariesPayload?.length === 0) {
      const payload = cloneDeep(payloadInStore);
      payload.beneficiaryDTOList = beneficiariesPayload;
      dispatch(setAnnuityPayloadInStore(cloneDeep(payload)));
      navigate(-1);
      return;
    }

    postValidateBeneficiaries(beneficiariesPayload)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          const payload = cloneDeep(payloadInStore);
          payload.beneficiaryDTOList = beneficiariesPayload;
          dispatch(setAnnuityPayloadInStore(cloneDeep(payload)));
          navigate(-1);
        }
      })
      .catch((err) => {
        if (err?.data?.beneErrors && err?.data?.beneErrors?.length) {
          setErrors(err?.data?.beneErrors[0]?.messages);
          scrollToTop();
        }
        if (
          err?.data?.combinedErrors &&
          err?.data?.combinedErrors?.hasSevereErrors
        ) {
          setErrors(err?.data?.combinedErrors?.messages);
          scrollToTop();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    singlePageLayoutContext({
      title: labels?.beneficiary?.title,
      buttonText: labels?.beneficiary?.cancel,
      buttonPath: -1,
      description: "",
    });

    if (payloadInStore?.beneficiaryDTOList) {
      const data = cloneDeep(payloadInStore?.beneficiaryDTOList);
      data.forEach((e) => {
        e.allocPct = parseFloat(e.allocPct * 100).toFixed(2);
      });
      setBeneficiaries([...data]);
    }
  }, []);

  useEffect(() => {
    getTotalAllocPct();
  }, [beneficiaries]);

  return (
    <section className="single-page-container py-3 add-update-beneficiary position-relative">
      {loading && <Spinner />}
      {errors && errors.length
        ? errors.map((e, index) => (
            <ErrorMessage key={index} error={e} wrapperClassName="py-1" />
          ))
        : ""}
      {/* Header  */}
      <>
        <div className="header title text-center mb-3">
          {labels?.beneficiary?.header}
        </div>
        <p className="description mb-3"> {labels?.beneficiary?.description}</p>
      </>

      {/* Beneficiary Type Dropdown  */}
      <>
        <div className="label">
          {labels?.beneficiary?.addAPrimaryBeneficiary}
        </div>
        <div className="normal">{labels?.beneficiary?.note}</div>
        <div className="d-flex align-items-center py-2">
          <SelectDropdown
            dropdownData={staticData?.beneTypes}
            wrapperClassName="col-6 col-md-3"
            selectClassName="w-100 py-2"
            select={(e) => setBeneficiaryType(e)}
            value={beneficiaryType}
            ariaLabel="Select Beneficiary Type"
          />
          <Button
            type="primary"
            text={labels?.beneficiary?.add}
            click={addNewBeneficiary}
            wrapperClassName="col-4 col-md-2 px-1"
            ariaLabel="Add Beneficiary"
          />
        </div>
      </>

      {/* Beneficiaries  */}
      <Formik initialValues={{}} validate={validateForm} validateOnBlur={true}>
        {({ errors, touched, setFieldTouched }) => (
          <Form>
            <FieldArray name="beneficiaries">
              <div>
                {beneficiaries.map((e, index) => (
                  <div key={e.id}>
                    <div className="bene flex-between-center py-3 mb-3">
                      <div
                        className="d-flex cursor-pointer"
                        onClick={() => updateClicked(index)}
                      >
                        {e.clicked && (
                          <MaterialIcon
                            iconName="expand_more"
                            iconClassName="icon vertical-middle"
                          />
                        )}
                        {!e.clicked && (
                          <MaterialIcon
                            iconName="chevron_right"
                            iconClassName="icon vertical-middle"
                          />
                        )}
                        {e.firstName} {e.middleName} {e.lastName} {e.fullName}
                        {` | ${e.beneficiaryType}`}
                      </div>
                      <div className="flex-between-center">
                        <InputField
                          type="number"
                          label={labels?.beneficiary?.percentage}
                          inputClass="w-50 mx-1"
                          percentage={true}
                          wrapperClassName="d-flex align-items-center justify-content-end"
                          min="0"
                          max="100"
                          step="0.01"
                          value={e.allocPct}
                          blur={(val) => updateData(val, index, "allocPct")}
                          decimal={false}
                          ariaLabel="Beneficiary Percentage"
                        />
                        <MaterialIcon
                          iconName="delete_forever"
                          wrapperClassName="mx-2 px-1 delete-icon"
                          click={() => deleteBeneficiary(index)}
                        />
                      </div>
                    </div>

                    {e.clicked && (
                      <div className="mb-3 py-0 fw-bold flex-between-center flex-wrap px-2">
                        {e.beneficiaryType === "Person" && (
                          <>
                            <InputField
                              type="text"
                              label={labels?.beneficiary?.firstName}
                              wrapperClassName="col-md-4 mb-3 col-12 col-sm-7 pe-2"
                              value={e.firstName}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "firstName"
                              )}
                              ariaLabel={`First Name for beneficiary number ${index}`}
                            />

                            <InputField
                              type="text"
                              label={labels?.beneficiary?.middleName}
                              wrapperClassName="col-md-4 mb-3 col-12 col-sm-7 px-2"
                              value={e.middleName}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "middleName"
                              )}
                              ariaLabel={`Middle Name for beneficiary number ${index}`}
                            />

                            <InputField
                              type="text"
                              label={labels?.beneficiary?.lastName}
                              wrapperClassName={`col-md-4 mb-3 col-12 col-sm-7 ps-2`}
                              value={e.lastName}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "lastName"
                              )}
                              ariaLabel={`Last Name for beneficiary number ${index}`}
                            />
                          </>
                        )}

                        {e.beneficiaryType !== "Person" && (
                          <InputField
                            type="text"
                            label={labels?.beneficiary?.fullName}
                            wrapperClassName={`col-md-4 mb-3 col-12 col-sm-7 pe-2`}
                            value={e.fullName}
                            {...errorAndTouchedProps(
                              errors,
                              touched,
                              setFieldTouched,
                              index,
                              "fullName"
                            )}
                            ariaLabel={`Full Name for beneficiary number ${index}`}
                          />
                        )}

                        <InputField
                          type="number"
                          label={
                            e.beneficiaryType === "Person"
                              ? labels?.beneficiary?.ssn
                              : labels?.beneficiary?.ein
                          }
                          wrapperClassName={`col-md-4 mb-3 col-12 col-sm-7 ${
                            e.beneficiaryType === "Person" ? "pe-2" : "px-2"
                          }`}
                          value={e.taxId}
                          {...errorAndTouchedProps(
                            errors,
                            touched,
                            setFieldTouched,
                            index,
                            "taxId"
                          )}
                          ariaLabel={`Tax ID for beneficiary number ${index}`}
                        />

                        <SelectDropdown
                          label={
                            e.beneficiaryType !== "Person"
                              ? labels?.beneficiary?.beneficiaryDetail
                              : labels?.beneficiary?.relationship
                          }
                          dropdownData={
                            e.beneficiaryType !== "Person"
                              ? staticData?.entityTypes
                              : staticData?.beneRelationships
                          }
                          wrapperClassName="col-md-4 col-12 col-sm-7 mb-3 px-2"
                          selectClassName="w-100"
                          select={(val) =>
                            updateData(val, index, "relationship")
                          }
                          value={e.relationship}
                          ariaLabel={`Relationship detail for beneficiary number ${index}`}
                        />

                        {e.beneficiaryType === "Person" && (
                          <>
                            <SelectDropdown
                              label={labels?.beneficiary?.gender}
                              dropdownData={staticData?.genders}
                              wrapperClassName="col-md-4 mb-3 col-12 col-sm-7 ps-2"
                              selectClassName="w-100"
                              select={(val) => updateData(val, index, "gender")}
                              value={e.gender}
                              optionLabel="name"
                              optionId="id"
                              ariaLabel={`Gender for beneficiary number ${index}`}
                            />
                            <Datepicker
                              wrapperClassName="col-md-4 col-12 col-sm-7 mb-3 pe-2"
                              label={labels?.beneficiary?.dateOfBirth}
                              max={date.format("YYYY-MM-DD")}
                              value={e.dob}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "dob"
                              )}
                              ariaLabel={`DOB for beneficiary number ${index}`}
                            />
                          </>
                        )}

                        {/* {e.relationship === "Spouse" && (
                          <>
                            <InputField
                              type="text"
                              label={labels?.beneficiary?.address}
                              wrapperClassName="mb-2 col-md-12"
                              value={e.address1}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "address1"
                              )}
                            />

                            <InputField
                              type="text"
                              wrapperClassName="mb-2 col-md-12"
                              value={e.address2}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "address2"
                              )}
                            />

                            <InputField
                              type="text"
                              wrapperClassName="mb-3 col-md-12"
                              value={e.address3}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "address3"
                              )}
                            />

                            <SelectDropdown
                              label={labels?.beneficiary?.country}
                              dropdownData={staticData?.countryList}
                              wrapperClassName="col-md-3"
                              selectClassName="w-100"
                              select={(val) =>
                                updateData(val, index, "country")
                              }
                              value={e.country}
                              optionLabel="governmentUnitName"
                              optionId="governmentUnitId"
                            />

                            <InputField
                              type="text"
                              label={labels?.beneficiary?.city}
                              wrapperClassName="col-md-3"
                              value={e.city}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "city"
                              )}
                            />

                            <SelectDropdown
                              label={labels?.beneficiary?.state}
                              dropdownData={staticData?.usStateList}
                              wrapperClassName="col-md-2"
                              selectClassName="w-100"
                              select={(val) =>
                                updateData(val, index, "stateCode")
                              }
                              value={e.stateCode}
                              optionLabel="governmentUnitName"
                              optionId="usStatePostalCd"
                            />

                            <InputField
                              type="text"
                              label={labels?.beneficiary?.zipCode}
                              wrapperClassName="col-md-3"
                              value={e.zipCode}
                              {...errorAndTouchedProps(
                                errors,
                                touched,
                                setFieldTouched,
                                index,
                                "zipCode"
                              )}
                            />
                          </>
                        )} */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </FieldArray>
          </Form>
        )}
      </Formik>

      {beneficiaries.length > 0 && (
        <div className="py-2 text-end pe-3 total">
          {labels?.beneficiary?.total} :{" "}
          <b>{percentageFormatterForDisplay(totalPercentage)}</b>
          <br />
          <small> {labels?.beneficiary?.mustEqual100}</small>
        </div>
      )}

      <div className="d-flex justify-content-center">
        <Button
          type="primary"
          text={
            payloadInStore?.beneficiaryDTOList?.length
              ? "Update"
              : labels?.beneficiary?.save
          }
          wrapperClassName="col-xl-3 col-md-5 col-6 py-5"
          click={validateBeneficiaries}
          ariaLabel="Save/Update Beneficiaries"
        />
      </div>
    </section>
  );
};

export default AddUpdateBeneficiary;
