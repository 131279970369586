import ArrowLink from "common/ArrowLink/ArrowLink";
import CurrencyBox from "common/CurrencyBox/CurrencyBox";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import InfoPopup from "common/InfoPopup/InfoPopup";
import Accordion from "react-bootstrap/Accordion";
import labels from "utils/LocalizedStrings";
import { DashboardContext } from "./Dashboard";
import { useContext, useEffect, useState } from "react";
import { amountFormatter, percentageFormatter } from "utils/Utilities";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutout: "65%",
};

const SectionThree = () => {
  const {
    participantDetails,
    allocations,
    balances,
    contractStatus,
    selectedTaxType,
  } = useContext(DashboardContext);
  const [doughnutData, setDoughnutData] = useState({
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#D27AB2",
          "#7FA4C9",
          "#E5EFCA",
          "#CA4A4D",
          "#122B54",
          "#B2B0B0",
        ],
        borderColor: [
          "#D27AB2",
          "#7FA4C9",
          "#E5EFCA",
          "#CA4A4D",
          "#122B54",
          "#B2B0B0",
        ],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    const allocationList = allocations?.allocationsInfoList;
    if (Array.isArray(allocationList)) {
      allocationList.forEach((e, index) => {
        doughnutData.datasets[0].data[index] =
          e.isrInvestmentVehicleCurAllocPctRt * 100;
      });

      setDoughnutData(doughnutData);
    }
  }, [allocations]);

  return (
    <div className="section-three mb-4">
      <Accordion defaultActiveKey="0" className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{labels?.dashboard?.balances}</Accordion.Header>
          <Accordion.Body>
            <div className="balances d-flex justify-content-around align-items-center gap-1">
              {balances ? (
                <>
                  <CurrencyBox
                    wrapperClassName="col-xl-3 col-md-4"
                    label={labels?.dashboard?.cashValue}
                    popoverText={labels?.dashboard?.cashValuePopover}
                    amount={balances?.cashValueAmount}
                    date={balances?.balancesAsOfDate}
                  />
                  <CurrencyBox
                    wrapperClassName="col-xl-3 col-md-4"
                    label={labels?.dashboard?.lifetimeIncomeValue}
                    popoverText={labels?.dashboard?.lifetimeIncomeValuePopover}
                    amount={balances?.lifeTimeIncomeAmount}
                    date={balances?.balancesAsOfDate}
                  />
                  <CurrencyBox
                    wrapperClassName="col-xl-3 col-md-4"
                    label={labels?.dashboard?.lumpSumDeathBenefit}
                    popoverText={labels?.dashboard?.lumpSumDeathBenefitPopover}
                    amount={balances?.lumpSumDeathBenefitAmount}
                    date={balances?.balancesAsOfDate}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>{labels?.dashboard?.allocations}</Accordion.Header>
          <Accordion.Body className="allocations-body">
            <div className="allocations">
              <div className="header border-bottom-1 mb-2">
                {labels?.dashboard?.myAllocations}
                <InfoPopup
                  position={"top"}
                  content={labels?.dashboard?.myAllocationsPopover}
                />
              </div>

              {Array.isArray(allocations?.allocationsInfoList) ? (
                <div className="d-flex justify-content-between doughChart">
                  <div className="col-xl-3 col-md-4 chart">
                    <Doughnut
                      data={doughnutData}
                      options={options}
                      aria-label="Allocations Chart"
                    />
                  </div>

                  <div className="col-xl-8 col-md-8">
                    <div className="text-end border-bottom-1 accumulation py-2 mb-2">
                      {labels?.dashboard?.accumulationValue} <br />
                      on&nbsp;
                      {allocations?.accumulationDate}
                    </div>

                    {allocations?.allocationsInfoList?.map((e, index) => (
                      <div
                        className="funds mb-2 py-2"
                        key={e.investmentVehicleId}
                      >
                        <span
                          className="color-box col-2 col-md-1"
                          style={{
                            backgroundColor:
                              doughnutData.datasets[0].backgroundColor[index],
                          }}
                        ></span>
                        <span className="col-5 col-md-6 fund">
                          {e.isrInvestmentVehicleNm}
                        </span>
                        <span className="col-md-3 amount">
                          {amountFormatter(e.isrInvestmentVehicleAmount)}
                        </span>
                        <span className="col-md-2 percentage">
                          {percentageFormatter(
                            e.isrInvestmentVehicleCurAllocPctRt
                          )}
                        </span>
                      </div>
                    ))}

                    <div className="border-bottom-1 mb-2"></div>

                    <div className="funds col-md-12">
                      <span className="col-md-1 w-5"></span>
                      <span className="col-md-6">
                        <div className="total fw-bold">
                          {labels?.dashboard?.total}
                        </div>
                        <div className="small">
                          {labels?.dashboard?.weightedInterestRate}
                        </div>
                      </span>
                      <span className="col-md-3 amount">
                        <b>{amountFormatter(allocations?.accumulationTotal)}</b>
                      </span>
                      <span className="col-md-2 percentage">
                        <b>100.00%</b>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="mb-4 description">
                {labels?.dashboard?.myAllocationsDescription1}
                <br />
                <br />
                {labels?.dashboard?.myAllocationsDescription2}
              </div>

              <div className="arrow-links">
                <ArrowLink
                  link={labels?.dashboard?.annuityAllocationGuideHref}
                  text={labels?.dashboard?.annuityAllocationGuide}
                  className="mb-2"
                />
                {!participantDetails?.managedAccount && (
                  <ArrowLink
                    path={`/change-allocations/1`}
                    text={labels?.dashboard?.changeAnnuityAllocations}
                    disabled={
                      (contractStatus &&
                        (["PENDING", "APPROVED"].indexOf(
                          contractStatus?.annuityStatus
                        ) > -1 ||
                          ["PENDING", "APPROVED"].indexOf(
                            contractStatus?.contractStatus
                          ) > -1)) ||
                      !selectedTaxType
                    }
                  />
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SectionThree;
