import Modal from "react-bootstrap/Modal";
import Button from "common/Button/Button";
import strings from "utils/LocalizedStrings";
import sanitize from "utils/SanitizeHtml";

const CancelPopup = ({
  showModal,
  setShowModal,
  pageName,
  primaryButtonClicked,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="session-popup"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="title">
          {strings?.cancelPopup?.header}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          dangerouslySetInnerHTML={sanitize(
            strings?.cancelPopup?.body?.replace("~pageTitle~", pageName)
          )}
        ></div>
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            text="No"
            wrapperClassName="py-4 col-md-5 me-2 col-4"
            click={() => setShowModal(false)}
          />
          <Button
            type="primary"
            text="Yes"
            wrapperClassName="py-4 col-md-5 col-4"
            click={() => primaryButtonClicked()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelPopup;
