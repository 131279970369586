import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import Alert from "react-bootstrap/Alert";

const ErrorMessage = ({
  wrapperClassName,
  errorClassName,
  errorCode,
  errorMessage,
  error,
}) => {
  return (
    <div className={`${wrapperClassName} p-0`}>
      <Alert
        key="danger"
        variant="danger"
        show={true}
        className={`${errorClassName} error-message mb-0`}
      >
        {error && (
          <div className="d-flex align-items-center">
            <MaterialIcon iconName="error" wrapperClassName="me-2" />
            {error.errorCode ? `${error.errorCode} - ` : ""}
            {error.errorMessage}
          </div>
        )}
      </Alert>
    </div>
  );
};

export default ErrorMessage;
ErrorMessage.defaultProps = {
  errorClassName: "",
  wrapperClassName: "",
};
