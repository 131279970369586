import { useEffect } from "react";
import { Popover } from "bootstrap/dist/js/bootstrap.esm.min.js";
import MaterialIcon from "common/MaterialIcon/MaterialIcon";

const InfoPopup = ({ position, title, content, iconClassName }) => {
  useEffect(() => {
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="popover"]')
    ).forEach((popoverNode) => new Popover(popoverNode));
  });

  return (
    <>
      <button
        type="button"
        className="infoPopup"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-custom-class="popover"
        data-bs-trigger="focus"
        data-bs-placement={position}
        title={title}
        data-bs-content={content}
        data-bs-html={true}
      >
        <MaterialIcon
          iconName="info"
          wrapperClassName="position-relative info-popup-icon"
          iconClassName={iconClassName}
        />
      </button>
    </>
  );
};

export default InfoPopup;
