import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { authApi, riccApi } from "service/APIService";
import { setupListeners } from "@reduxjs/toolkit/query";
import authSlice from "./authSlice";
import changeAllocationSlice from "./changeAllocationSlice";
import commonSlice from "./commonSlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [authApi.reducerPath, riccApi.reducerPath],
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [riccApi.reducerPath]: riccApi.reducer,
  authReducer: authSlice,
  changeAllocationReducer: changeAllocationSlice,
  commonReducer: commonSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware, riccApi.middleware),
});

setupListeners(store.dispatch);
export default store;
