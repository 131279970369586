import Button from "common/Button/Button";
import { SinglePageLayoutContext } from "layout/SinglePageLayout";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { getMonthName, getTaxTypeLabel } from "utils/Utilities";
import labels from "utils/LocalizedStrings";
import sanitize from "utils/SanitizeHtml";
import { Fragment } from "react";

const Notifications = () => {
  const navigate = useNavigate();
  const singlePageLayoutContext = useContext(SinglePageLayoutContext);
  const contractStatus = useSelector(
    (state) => state?.commonReducer?.annuityStatusInStore
  );
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const selectedTaxTypeInStore = useSelector(
    (state) => state?.commonReducer?.selectedTaxTypeInStore
  );
  const [dateDisplayWithYear, setDateDisplayWithYear] = useState("");

  useEffect(() => {
    singlePageLayoutContext({
      title: labels?.notifications?.title,
      buttonText: labels?.notifications?.close,
      buttonPath: "/dashboard",
      description: "",
      closeButton: true,
    });

    getDate();
  }, []);

  function goToDashboard() {
    navigate("/dashboard");
  }
  function goToAnnuity() {
    navigate("/change-allocations/1");
  }
  function getDate() {
    const dob = participantDetails.dob;
    if (dob) {
      const dobDate = dob.split("/");
      const currentDate = dayjs().format("MM/DD/YYYY").split("/");

      setDateDisplayWithYear(
        `${getMonthName(dobDate[0])} ${dobDate[1]}, ${currentDate[2]}`
      );
    }
  }
  function checkOneMonthDiff() {
    const dob = participantDetails.dob;
    if (dob) {
      const dobDate = dob.split("/");
      const current = dayjs().format("MM/DD/YYYY").split("/");

      const original = dayjs(dobDate[0] + "/" + dobDate[1] + "/" + current[2]);
      const diff = original.diff(dayjs().format("MM/DD/YYYY"), "day");
      return diff <= 30 && diff >= 1;
    } else {
      return false;
    }
  }

  return (
    <section className="single-page-container">
      <div className="mb-2">
        {labels?.notifications?.hi} {participantDetails?.firstName}{" "}
        {participantDetails?.middleName} {participantDetails?.lastName},
      </div>

      <Fragment>
        {contractStatus &&
        ["PENDING", "APPROVED"].indexOf(contractStatus?.annuityStatus) > -1 ? (
          <span className="annuityMessage">
            {contractStatus?.annuityStatus === "PLAN SEPARATION"
              ? labels?.cashSection?.annuityInPendingForTakeAnnuity
              : labels?.cashSection?.annuityInPendingForStartIncome}
          </span>
        ) : (
          ""
        )}
      </Fragment>

      <Fragment>
        {contractStatus &&
        ["PENDING", "APPROVED"].indexOf(contractStatus?.contractStatus) > -1 ? (
          <span className="annuityMessage">
            {labels?.cashSection?.contractInPending.replace(
              "~taxType~",
              getTaxTypeLabel(selectedTaxTypeInStore)
            )}
          </span>
        ) : (
          ""
        )}
      </Fragment>

      {["NONE", "REJECTED"].indexOf(contractStatus?.annuityStatus) > -1 &&
      contractStatus?.contractStatus === "VALID" &&
      checkOneMonthDiff() ? (
        <Fragment>
          <p
            className="mb-2 normal"
            dangerouslySetInnerHTML={sanitize(
              labels?.notifications?.eligible?.replace(
                "~dateDisplayWithYear~",
                dateDisplayWithYear
              )
            )}
          ></p>
          <p className="normal">{labels?.notifications?.selectAPLIP}</p>
          <div className="d-flex justify-content-center flex-column align-items-center py-4">
            <Button
              wrapperClassName="col-xl-3 col-md-5 mb-3"
              type="primary"
              text={labels?.notifications?.changeAnnuityAllocations}
              click={goToAnnuity}
              disabled={
                contractStatus &&
                (["PENDING", "APPROVED"].indexOf(
                  contractStatus?.annuityStatus
                ) > -1 ||
                  ["PENDING", "APPROVED"].indexOf(
                    contractStatus?.contractStatus
                  ) > -1)
              }
            />
            <Button
              wrapperClassName="col-xl-3 col-md-5"
              type="secondary"
              text={labels?.notifications?.skip}
              click={goToDashboard}
            />
          </div>
        </Fragment>
      ) : (
        ""
      )}
    </section>
  );
};

export default Notifications;
