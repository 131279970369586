import InfoPopup from "common/InfoPopup/InfoPopup";
import { useSelector } from "react-redux";
import { useGetAccumulationQuery } from "service/APIService";
import labels from "utils/LocalizedStrings";
import sanitize from "utils/SanitizeHtml";
import {
  amountFormatter,
  getRequiredIds,
  getTaxTypeLabel,
} from "utils/Utilities";

const CashSection = ({ guestUser }) => {
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const contractStatus = useSelector(
    (state) => state?.commonReducer?.annuityStatusInStore
  );
  const selectedTaxTypeInStore = useSelector(
    (state) => state?.commonReducer?.selectedTaxTypeInStore
  );
  const { data: accumulationInfo } = useGetAccumulationQuery(
    getRequiredIds(participantDetails),
    {
      skip: guestUser,
    }
  );

  if (guestUser) {
    return (
      <section className="row cashSection align-items-center">
        <div className="text-section">
          <div className="title text-center x-large pt-4">
            {labels?.cashSection?.guestParticipantHeader}
          </div>
          <p
            className="description text-center py-4"
            dangerouslySetInnerHTML={sanitize(
              labels?.cashSection?.guestParticipantDescription
            )}
          ></p>
        </div>
      </section>
    );
  } else {
    return (
      <section className="row cashSection">
        <div className="p-0 d-flex justify-content-between cashSection-mobile">
          <div className="col-xl-9 col-md-9 text-section">
            <div
              className="title mb-3"
              dangerouslySetInnerHTML={sanitize(labels?.cashSection?.header)}
            ></div>

            <div className="description">
              {labels?.cashSection?.description}
            </div>

            {contractStatus &&
            ["PENDING", "APPROVED"].indexOf(contractStatus?.annuityStatus) >
              -1 ? (
              <span className="annuityMessage">
                {contractStatus?.annuityStatus === "PLAN SEPARATION"
                  ? labels?.cashSection?.annuityInPendingForTakeAnnuity
                  : labels?.cashSection?.annuityInPendingForStartIncome}
              </span>
            ) : (
              ""
            )}

            {contractStatus &&
            ["PENDING", "APPROVED"].indexOf(contractStatus?.contractStatus) >
              -1 ? (
              <span className="annuityMessage">
                {labels?.cashSection?.contractInPending.replace(
                  "~taxType~",
                  getTaxTypeLabel(selectedTaxTypeInStore)
                )}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="border-div"></div>

          <div className="col-xl-2 col-md-2 d-flex justify-content-center cashValue">
            <div className="cash-section">
              <div className="cash-value">{labels?.cashSection?.cashValue}</div>
              {accumulationInfo?.asOfDate !== null && (
                <div className="date">
                  {labels?.cashSection?.asOf} {accumulationInfo?.asOfDate}
                </div>
              )}
              {accumulationInfo?.asOfDate !== null ? (
                <div className="amount">
                  {amountFormatter(accumulationInfo?.totalAccumulationAmount)}
                </div>
              ) : (
                "N/A"
              )}
              <InfoPopup
                position={"left"}
                content={labels?.cashSection?.cashValuePopover}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default CashSection;
