import { local, dev, qat, cat, prod } from "utils/env";

export const loadConfig = () => {
  const environment = process.env.REACT_APP_NODE_ENV;

  switch (environment) {
    case "dev":
      return dev;
    case "qat":
      return qat;
    case "cat":
      return cat;
    case "prod":
      return prod;
    default:
      return local;
  }
};
