import Button from "common/Button/Button";
import sanitize from "utils/SanitizeHtml";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";

const IncomeOption = ({ context }) => {
  const navigate = useNavigate();
  const { labels } = useContext(context);

  const [readMore, setReadMore] = useState(0);
  const next = () => navigate("/start-income/1");

  return (
    <>
      <section className="border-bottom-1">
        <>
          <div className="label mb-1">{labels?.startIncome?.option1}</div>

          <p className="description">
            {labels?.startIncome?.option1Description}&nbsp;
            {readMore !== 1 && (
              <span
                className="title text-decoration-underline cursor-pointer"
                onClick={() => setReadMore(1)}
              >
                {labels?.startIncome?.readMore}
              </span>
            )}
          </p>

          {readMore === 1 && (
            <p
              className="description"
              dangerouslySetInnerHTML={sanitize(
                labels?.startIncome?.option1DescriptionMore
              )}
            ></p>
          )}
        </>

        <>
          <div className="label mb-1">{labels?.startIncome?.option2}</div>
          <p className="description">
            {labels?.startIncome?.option2Description}&nbsp;
            {readMore !== 2 && (
              <span
                className="title text-decoration-underline cursor-pointer"
                onClick={() => setReadMore(2)}
              >
                {labels?.startIncome?.readMore}
              </span>
            )}
          </p>
          {readMore === 2 && (
            <p
              className="description"
              dangerouslySetInnerHTML={sanitize(
                labels?.startIncome?.option2DescriptionMore
              )}
            ></p>
          )}
        </>

        <p className="description">
          {labels?.startIncome?.whicheverMethodYouChoose}
        </p>
      </section>

      <section className="py-4">
        <div className="header title text-center mb-3">
          {labels?.startIncome?.howDoIStart}
        </div>
        <p className="description">{labels?.startIncome?.ifYouWantToStart}</p>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            text={labels?.startIncome?.continue}
            wrapperClassName="col-3 col-md-3 d-flex justify-content-center"
            click={next}
          />
        </div>
      </section>
    </>
  );
};

export default IncomeOption;
