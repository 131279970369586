import Checkbox from "common/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import SuccessPopup from "common/SuccessPopup/SuccessPopup";
import RowHeader from "common/RowHeader/RowHeader";
import Row from "common/Row/Row";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import { usePostChangeAnnuityMutation } from "service/APIService";
import cloneDeep from "lodash/cloneDeep";
import { getCountryId, scrollToTop } from "utils/Utilities";

const SubmitInformation = ({ from, context }) => {
  const navigate = useNavigate();
  const {
    labels,
    payload,
    updatePayload,
    staticData,
    getStateName,
    participantDetails,
    selectedTaxTypeInStore,
    setLoading,
    attachment,
    getStateId,
    getRequiredIds,
    getCountryName,
    setErrors,
  } = useContext(context);
  const [showModal, setShowModal] = useState(false);
  const [postChangeAnnuity] = usePostChangeAnnuityMutation();

  function back() {
    navigate(-1);
  }

  function makeChanges() {
    if (from === "startIncome") {
      navigate("/start-income/1");
    } else {
      navigate("/take-annuity-out-of-plan/1");
    }
  }

  async function submit() {
    setLoading(true);
    setErrors([]);

    const body = cloneDeep(payload);

    if (!attachment) {
      body.attachmentFormat = null;
    }

    var formData = new FormData();
    formData.append("attachment", attachment[0]);
    formData.append(
      "annuityRequestDTO",
      new Blob(
        [
          JSON.stringify({
            ...getRequiredIds(participantDetails, selectedTaxTypeInStore),
            ...body,
            stateCode:
              payload.countryCode === "US"
                ? getStateId(payload?.stateCode, staticData)
                : getCountryId(payload.countryCode, staticData),
            countryNm: getCountryName(payload?.countryCode, staticData),
            zipCode: payload.zipCode.replace("-", ""),
            electionType:
              payload?.activityType === "Income" ? payload?.electionType : "",
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    postChangeAnnuity(formData)
      .then((res) => {
        if (res?.data?.success) {
          setShowModal(true);
        } else if (res?.data?.hasSevereErrors) {
          setErrors(res?.data?.messages);
        }
      })
      .catch((err) => {
        if (err?.data?.messages) {
          setErrors(err?.data?.messages);
          scrollToTop();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function redirectToSetupAccount() {
    if (from === "startIncome") {
      navigate("/setup-account?from=start-income");
    } else {
      navigate("/setup-account?from=take-annuity-out-of-plan");
    }
  }

  return (
    <section className="section-three py-2">
      <RowHeader
        header={labels?.[from]?.requiredInformation}
        buttonLabel={labels?.[from]?.makeChanges}
        click={makeChanges}
      />
      <Row
        leftValue={labels?.[from]?.areYouUSCitizen}
        rightValue={payload?.usCitizen == "true" ? "Yes" : "No"}
      />
      <Row
        leftValue={labels?.[from]?.areYouNonResidentAlien}
        rightValue={payload?.nonResidentAlien == "true" ? "Yes" : "No"}
      />
      <Row
        leftValue={labels?.[from]?.address}
        rightValue={
          payload?.address1 +
          " " +
          payload?.address2 +
          " " +
          payload?.address3 +
          ", " +
          payload?.city +
          ", " +
          (payload.countryCode === "US"
            ? getStateName(payload?.stateCode, staticData) + ", "
            : "") +
          getCountryName(payload?.countryCode, staticData) +
          ", " +
          payload?.zipCode
        }
      />

      {payload?.beneficiaryDTOList && payload?.beneficiaryDTOList?.length
        ? payload?.beneficiaryDTOList.map((e) => (
            <Row
              leftValue={labels?.[from]?.beneficiary}
              rightValue={
                e.fullName ||
                e.firstName + " " + e.middleName + " " + e.lastName
              }
            />
          ))
        : ""}

      <Checkbox
        wrapperClassName="pt-4"
        id="terms"
        label={labels?.[from]?.acceptTerms}
        terms={labels?.[from]?.terms}
        change={(e) => updatePayload(e, "consentAccepted")}
        value={payload?.consentAccepted}
        ariaLabel="Terms and Conditions"
      />

      <NavigationButtons
        leftLabel={labels?.[from]?.back}
        leftClick={back}
        rightLabel={labels?.[from]?.submit}
        rightClick={submit}
        rightDisabled={!payload?.consentAccepted}
        leftClassName="col-4"
        rightClassName="col-4"
      />

      <SuccessPopup
        showModal={showModal}
        buttonText={labels?.[from]?.setupAccount}
        click={redirectToSetupAccount}
        confirmationId="000"
      />
    </section>
  );
};

export default SubmitInformation;
