import useEventTracker from "common/Tracker/EventTracker";

const defaultChange = () => {};

const FileInput = ({ value, onChange = defaultChange, ...rest }) => {
  const gaEventTracker = useEventTracker({});
  return (
    <div className="d-flex align-items-center">
      <label className="ms-0">
        {Boolean(value.length) && (
          <span className="inputFileButton form-control cursor-pointer">
            Choose File
          </span>
        )}
        <input
          {...rest}
          style={{ display: Boolean(value.length) ? "none" : "block" }}
          type="file"
          aria-label="File Upload"
          onChange={(e) => {
            if (e.target.value) {
              gaEventTracker(
                "File Upload",
                "User uploaded a file",
                "File Upload"
              );
              onChange([...e.target.files]);
            }
          }}
        />
      </label>
      {Boolean(value.length) && (
        <div className="ms-3">{value.map((f) => f.name).join(", ")}</div>
      )}
    </div>
  );
};

export default FileInput;
