import DOMPurify from "dompurify";

const options = {
  ALLOWED_TAGS: ["b", "i", "em", "strong", "a", "div", "br", "span"],
  ALLOWED_ATTR: ["href"],
};

const sanitize = (dirty) => ({
  __html: DOMPurify.sanitize(dirty, { ...options }),
});

export default sanitize;
