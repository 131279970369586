const MaterialIcon = ({
  wrapperClassName,
  iconClassName,
  iconName,
  click,
  children,
}) => {
  const onClick = () => {
    if (click) {
      click();
    }
  };
  return (
    <div className={`${wrapperClassName}`} onClick={onClick}>
      <span
        className={`material-symbols-outlined vertical-middle ${iconClassName}`}
      >
        {iconName}
      </span>
      {children}
    </div>
  );
};

export default MaterialIcon;
MaterialIcon.defaultProps = {
  wrapperClassName: "",
  iconClassName: "",
};
