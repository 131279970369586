import ArrowLink from "common/ArrowLink/ArrowLink";
import labels from "utils/LocalizedStrings";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "./Dashboard";

const SectionTwo = () => {
  const {
    taxTypes,
    participantDetails,
    balances,
    selectedTaxType,
    contractStatus,
  } = useContext(DashboardContext);
  const [annuityLinks, setAnnuityLinks] = useState([
    {
      text: labels?.dashboard?.changeAnnuityAllocations,
      path: "/change-allocations/1",
      id: "changeAllocations",
      display: true,
      disabled: false,
    },
    {
      text: labels?.dashboard?.takeAnnuityOutOfPlan,
      path: "/take-annuity-out-of-plan/1",
      id: "takeAnnuityOutOfPlan",
      display: true,
      disabled: false,
    },
    {
      text: labels?.dashboard?.startIncome,
      path: "/start-income",
      id: "startIncome",
      display: true,
      disabled: false,
    },
  ]);

  const getContractName = (code) => {
    return taxTypes?.find((e) => e.taxTypeCode == code)?.taxTypeName;
  };

  useEffect(() => {
    if (participantDetails?.managedAccount) {
      const annuityLink = annuityLinks.find(
        (e) => e.id === "changeAllocations"
      );
      annuityLink.display = false;
      setAnnuityLinks([...annuityLinks]);
    }
  }, [participantDetails]);

  return (
    <div className="section-two d-flex justify-content-between mb-2">
      <div className="col-xl-8 col-md-7 details">
        <div className="title mb-2">{getContractName(selectedTaxType)}</div>
        {balances?.contractId && (
          <div className="mb-2">
            {labels?.dashboard?.contract} {balances?.contractId}
          </div>
        )}
        {balances?.contractDate && (
          <div>
            {labels?.dashboard?.contractDate} {balances?.contractDate}
          </div>
        )}
      </div>

      <div className="border-div"></div>

      <div className="col-xl-3 col-md-4 links">
        <div className="header">{labels?.dashboard?.manageYourAnnuity}</div>
        <div className="arrow-links">
          {annuityLinks.map((e) =>
            e.display ? (
              <ArrowLink
                key={e.path}
                path={e.path}
                text={e.text}
                iconClassName="mr-10"
                disabled={
                  (contractStatus &&
                    (["PENDING", "APPROVED"].indexOf(
                      contractStatus?.annuityStatus
                    ) > -1 ||
                      ["PENDING", "APPROVED"].indexOf(
                        contractStatus?.contractStatus
                      ) > -1)) ||
                  !selectedTaxType ||
                  e.disabled
                }
              />
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
