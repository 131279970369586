import Contracts from "./Contracts";
import Statements from "./Statements";
import SectionFooter from "common/Footer/SectionFooter";
import Spinner from "common/Spinner/Spinner";
import { useEffect, useState } from "react";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";
import { useSelector } from "react-redux";
import { useGetStaticDataQuery } from "service/APIService";
import labels from "utils/LocalizedStrings";
import cloneDeep from "lodash/cloneDeep";

const Documents = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const { data: staticData, isFetching: loading1 } = useGetStaticDataQuery();

  useEffect(() => {
    if (error && error?.errorCode) {
      const err = cloneDeep(error);
      err["errorMessage"] = labels?.documents?.[err?.type];
      err["errorCode"] = "";
      setError(err);
    }
  }, [error]);

  return (
    <main id="documents">
      <section className="dashboard-layout shadow-lg row py-4">
        {(loading || loading1) && <Spinner />}
        {error && !loading && <ErrorMessage error={error} />}
        <Contracts
          setLoading={setLoading}
          setError={setError}
          participantDetails={participantDetails}
        />
        <Statements
          setLoading={setLoading}
          setError={setError}
          participantDetails={participantDetails}
          staticData={staticData}
        />
      </section>
      <SectionFooter page="documents" />
    </main>
  );
};

export default Documents;
