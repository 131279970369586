import CancelPopup from "common/CancelPopup/CancelPopup";
import SuccessPopup from "common/SuccessPopup/SuccessPopup";
import Navbar from "common/Navbar/Navbar";
import Titlebar from "common/Titlebar/Titlebar";
import { createContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const SinglePageLayoutContext = createContext();

const SinglePageLayout = ({ children }) => {
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const setTitles = useCallback((res) => {
    setData(res);
  }, []);
  const returnToDashboard = () => navigate("/dashboard");

  return (
    <SinglePageLayoutContext.Provider value={setTitles}>
      <div id="SinglePageLayout" className={`${data.id}`}>
        <CancelPopup
          showModal={showModal}
          setShowModal={setShowModal}
          primaryButtonClicked={() => {
            setShowModal(false);
            navigate(data?.buttonPath);
          }}
          pageName={data?.title}
        />
        <Titlebar showName={false} showNotifications={false} />
        <Navbar
          layout="singlePageLayout"
          data={data}
          click={() => setShowModal(true)}
        />

        <main className="row justify-content-center py-4">
          <section className="single-page-layout p-0">{children}</section>
        </main>
        <SuccessPopup
          showModal={data?.confirmationId}
          buttonText={data?.popupText}
          click={returnToDashboard}
          confirmationId={data?.confirmationId}
        />
      </div>
    </SinglePageLayoutContext.Provider>
  );
};

export default SinglePageLayout;
