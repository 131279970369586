import labels from "utils/LocalizedStrings";

const SectionFooter = ({ page }) => {
  const footerData = labels?.[page]?.footer;

  if (footerData) {
    return (
      <section className="sectionFooter">
        {footerData.map((e) => {
          if (typeof e == "string") {
            return <p key={e}>{e}</p>;
          } else {
            return (
              <p className="box" key={e}>
                {e.map((listItem) => (
                  <span key={listItem}> &bull; {listItem}</span>
                ))}
              </p>
            );
          }
        })}
      </section>
    );
  } else {
    return <>Footer Data Not Found</>;
  }
};

export default SectionFooter;
