import InfoPopup from "common/InfoPopup/InfoPopup";

const RadioButton = ({
  data,
  wrapperClassName,
  radioClassName,
  value,
  selected,
  infoPopup,
  infoPopupContent,
  children,
  disabled,
  ariaLabel,
}) => {
  function change(e) {
    selected(e.target.id);
  }
  return (
    <div className={`radio-button ${wrapperClassName}`}>
      <div className="radio-header mb-1">
        {data?.header}
        {infoPopup && (
          <InfoPopup position={"right"} content={infoPopupContent} />
        )}
      </div>
      <div className={`${radioClassName}`}>
        {data?.data?.map((e, index) => {
          return (
            <div className="form-check me-4" key={e.id + index}>
              <input
                className="form-check-input"
                type="radio"
                name={data.name + "-" + index}
                id={e.id}
                checked={e.id == value}
                onChange={change}
                disabled={disabled || e.disabled}
                aria-label={e.ariaLabel}
              />
              <label className="" htmlFor={data.name + "-" + index}>
                {e.label}
              </label>
              {e.displayChildren ? e.children : ""}
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};

export default RadioButton;
RadioButton.defaultProps = {
  wrapperClassName: "",
  selected: () => {},
};
