import Button from "common/Button/Button";
import RadioButton from "common/Radio/RadioButton";
import { useNavigate } from "react-router-dom";
import { Fragment, useContext } from "react";
import RowTable from "common/RowTable/RowTable";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import { usePostValidateOwnerInfoMutation } from "service/APIService";
import cloneDeep from "lodash/cloneDeep";
import {
  getCountryId,
  percentageFormatter,
  scrollToTop,
} from "utils/Utilities";
import FileInput from "common/FileInput/FileInput";

const ProvideInformation = ({ from, context }) => {
  const navigate = useNavigate();
  const {
    labels,
    payload,
    updatePayload,
    staticData,
    setAttachment,
    participantDetails,
    selectedTaxTypeInStore,
    setLoading,
    attachment,
    getStateId,
    getRequiredIds,
    getCountryName,
    setErrors,
    accept,
  } = useContext(context);
  const [postValidateOwnerInfoMutation] = usePostValidateOwnerInfoMutation();

  const citizenData = {
    name: "Citizen",
    header: labels?.[from]?.areYouUSCitizen,
    data: [
      {
        label: labels?.[from]?.yes,
        id: "true",
        ariaLabel: "US Citizen",
      },
      {
        label: labels?.[from]?.no,
        id: "false",
        ariaLabel: "Non US Citizen",
      },
    ],
  };

  const nonResidentData = {
    name: "non-resident",
    header: labels?.[from]?.areYouNonResidentAlien,
    data: [
      {
        label: labels?.[from]?.yes,
        id: "true",
        ariaLabel: "Non Resident Alien",
      },
      {
        label: labels?.[from]?.no,
        id: "false",
        ariaLabel: "Resident Alien",
      },
    ],
  };

  const fileUploadData = {
    name: "attach-driving-license",
    header: labels?.[from]?.attachDrivingLicense,
    data: [
      {
        label: labels?.[from]?.pdf,
        id: "PDF",
        ariaLabel: "PDF File",
      },
      {
        label: labels?.[from]?.image,
        id: "JPEG",
        ariaLabel: "JPEG File",
      },
    ],
  };

  const electionTypeData = {
    name: "election-type",
    header: labels?.[from]?.electionType,
    data: [
      {
        label: labels?.[from]?.single,
        id: "S",
        ariaLabel: "Election Type - Single",
      },
      {
        label: labels?.[from]?.joint,
        id: "J",
        ariaLabel: "Election Type - Joint",
      },
    ],
  };

  const back = () => navigate(-1);
  function next() {
    setLoading(true);
    setErrors([]);
    const body = cloneDeep(payload);

    if (!attachment) {
      body.attachmentFormat = null;
    }

    var formData = new FormData();
    formData.append("attachment", attachment[0]);
    formData.append(
      "annuityRequestDTO",
      new Blob(
        [
          JSON.stringify({
            ...getRequiredIds(participantDetails, selectedTaxTypeInStore),
            ...body,
            stateCode:
              payload.countryCode === "US"
                ? getStateId(payload?.stateCode, staticData)
                : getCountryId(payload.countryCode, staticData),
            countryNm: getCountryName(payload?.countryCode, staticData),
            zipCode: body?.zipCode?.replace("-", ""),
            electionType:
              payload?.activityType === "Income" ? payload?.electionType : "",
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    postValidateOwnerInfoMutation(formData)
      .unwrap()
      .then((res) => {
        if (!res?.hasSevereErrors) {
          if (from === "startIncome") {
            navigate("/start-income/4");
          } else {
            navigate("/take-annuity-out-of-plan/3");
          }
        }
      })
      .catch((err) => {
        if (err?.data?.messages) {
          setErrors(err?.data?.messages);
          scrollToTop();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const addUpdateBeneficiary = () => navigate("/add-update-beneficiary");

  return (
    <section className="section-two py-2">
      <RadioButton
        data={citizenData}
        wrapperClassName="mb-3 pb-3 border-bottom-1"
        radioClassName="d-flex "
        value={payload?.usCitizen}
        selected={(e) => updatePayload(e, "usCitizen")}
      />

      {payload.usCitizen !== "true" && (
        <RadioButton
          data={nonResidentData}
          wrapperClassName="mb-3 pb-3 border-bottom-1"
          radioClassName="d-flex "
          value={payload?.nonResidentAlien}
          selected={(e) => updatePayload(e, "nonResidentAlien")}
        />
      )}

      <RadioButton
        data={fileUploadData}
        wrapperClassName="mb-3 pb-3 border-bottom-1"
        radioClassName="d-flex "
        infoPopup={true}
        infoPopupContent={labels?.[from]?.supportsPDFandImage}
        value={payload?.attachmentFormat}
        selected={(e) => updatePayload(e, "attachmentFormat")}
      >
        <div className="pt-2" key={payload?.attachmentFormat}>
          <FileInput
            value={attachment}
            accept={accept}
            id="file-attachment"
            onChange={(e) => {
              setAttachment(e);
            }}
          />
        </div>
      </RadioButton>

      {payload?.activityType === "Income" && (
        <RadioButton
          data={electionTypeData}
          wrapperClassName="mb-3 pb-3 border-bottom-1"
          radioClassName="d-flex"
          value={payload?.electionType}
          selected={(e) => updatePayload(e, "electionType")}
        />
      )}

      <section>
        <div className="flex-between-center">
          <div className="label text-start">
            {labels?.[from]?.beneficiaryInformation}
          </div>
          <Button
            text={labels?.[from]?.addBeneficiary}
            type="makeChanges"
            icon="edit"
            click={addUpdateBeneficiary}
          />
        </div>

        <RowTable rowClassName="desktop">
          <Fragment key="row-header">
            <span className="col-md-3">{labels?.[from]?.name}</span>
            <span className="col-md-2">{labels?.[from]?.type}</span>
            <span className="col-md-3">{labels?.[from]?.dateOfBirth}</span>
            <span className="col-md-2 text-end">
              {labels?.[from]?.relationship}
            </span>
            <span className="col-md-2 text-end">
              {labels?.[from]?.percentage}
            </span>
          </Fragment>

          <Fragment key="row-body">
            <>
              {payload?.beneficiaryDTOList &&
              payload?.beneficiaryDTOList?.length ? (
                payload?.beneficiaryDTOList.map((e) => {
                  return (
                    <div className="col-12 d-flex align-items-center">
                      <span className="col-md-3 py-2">
                        {e.firstName
                          ? e.firstName + " " + e.middleName + " " + e.lastName
                          : e.fullName}
                      </span>
                      <span className="col-md-2">{e.beneficiaryType}</span>
                      <span className="col-md-3">{e.dob}</span>
                      <span className="col-md-2 text-end">
                        {e?.relationship}
                      </span>
                      <span className="col-md-2 text-end">
                        {percentageFormatter(e?.allocPct)}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="col-12 text-center py-3">
                  <b>{labels?.[from]?.pleaseAddABeneficiary}</b>
                </div>
              )}
            </>
          </Fragment>
        </RowTable>

        <div className="row-table mobile">
          {payload?.beneficiaryDTOList &&
          payload?.beneficiaryDTOList?.length ? (
            payload?.beneficiaryDTOList.map((e) => {
              return (
                <div className="beneficiaryTable mb-3">
                  <div>
                    <span className="col-md-3">{labels?.[from]?.name}</span>
                    <span className="col-md-3">
                      {e.firstName
                        ? e.firstName + " " + e.middleName + " " + e.lastName
                        : e.fullName}
                    </span>
                  </div>

                  <div>
                    <span className="col-md-2">{labels?.[from]?.type}</span>
                    <span className="col-md-2">{e.beneficiaryType}</span>
                  </div>

                  <div>
                    <span className="col-md-3">
                      {labels?.[from]?.dateOfBirth}
                    </span>
                    <span className="col-md-3">{e.dob}</span>
                  </div>

                  <div>
                    <span className="col-md-2">
                      {labels?.[from]?.relationship}
                    </span>
                    <span className="col-md-2">{e?.relationship}</span>
                  </div>

                  <div>
                    <span className="col-md-2">
                      {labels?.[from]?.percentage}
                    </span>
                    <span className="col-md-2">
                      {percentageFormatter(e?.allocPct)}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12 text-center py-3">
              <b>{labels?.[from]?.pleaseAddABeneficiary}</b>
            </div>
          )}
        </div>
      </section>

      <NavigationButtons
        leftLabel={labels?.[from]?.back}
        leftClick={back}
        rightLabel={labels?.[from]?.next}
        rightClick={next}
        leftClassName="col-4"
        rightClassName="col-4"
      />
    </section>
  );
};

export default ProvideInformation;
