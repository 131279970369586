import CashSection from "common/CashSection/CashSection";
import Footer from "common/Footer/Footer";
import Navbar from "common/Navbar/Navbar";
import Titlebar from "common/Titlebar/Titlebar";

const MultiPageLayout = ({ guestUser, children }) => {
  return (
    <>
      <Titlebar guestUser={guestUser} />
      <Navbar guestUser={guestUser} />
      {<CashSection guestUser={guestUser} />}
      <>{children}</>
      <Footer />
    </>
  );
};

export default MultiPageLayout;
