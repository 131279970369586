import ReactGA from "react-ga4";

const useEventTracker = ({ category, action, label }) => {
  const eventTracker = (category, action, label) => {
    ReactGA.event(category, {
      category,
      action,
      label,
    });
  };
  return eventTracker;
};

export default useEventTracker;
