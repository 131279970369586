import Button from "common/Button/Button";

const RowHeader = ({ header, buttonLabel, click, wrapperClassName }) => {
  return (
    <div
      className={`flex-between-center border-bottom-1-dark py-2 ${wrapperClassName}`}
    >
      <div className="header">{header}</div>
      {buttonLabel && (
        <Button
          text={buttonLabel}
          type="makeChanges"
          icon="edit"
          click={click}
          wrapperClassName="col-6 d-flex justify-content-end"
        />
      )}
    </div>
  );
};

export default RowHeader;
