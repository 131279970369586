import RadioButton from "common/Radio/RadioButton";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import InfoPopup from "common/InfoPopup/InfoPopup";
import dayjs from "dayjs";

const SelectIncomeOption = ({ context }) => {
  const navigate = useNavigate();
  const { labels, payload, updatePayload, participantDetails } =
    useContext(context);

  const [incomeOption, setIncomeOption] = useState({
    name: "incomeOption",
    header: labels?.startIncome?.selectHowWouldYouLike,
    data: [
      {
        label: labels?.startIncome?.lifetimeWithdrawal,
        id: "Income",
        ariaLabel: "Income",
        disabled: false,
        displayChildren: false,
        children: (
          <InfoPopup
            position="top"
            content={labels?.startIncome?.notEligibleForIncome}
          />
        ),
      },
      {
        label: labels?.startIncome?.annuitization,
        id: "Annuitization",
        ariaLabel: "Annuitization",
        disabled: false,
        displayChildren: true,
        children: (
          <InfoPopup
            position="right"
            content={labels?.startIncome?.notEligibleForAnnuitization}
          />
        ),
      },
    ],
  });

  const back = () => navigate(-1);
  const next = () => navigate("/start-income/2");

  useEffect(() => {
    if (participantDetails?.dob) {
      const dob = dayjs(participantDetails.dob);
      const age = dayjs().diff(dob, "year");
      if (age < 60) {
        const idx = incomeOption.data.findIndex((e) => e.id === "Income");
        incomeOption.data[idx].disabled = true;
        incomeOption.data[idx].displayChildren = true;
        setIncomeOption({ ...incomeOption });
        updatePayload("Annuitization", "activityType");
      }
    }
  }, []);

  return (
    <>
      <RadioButton
        data={incomeOption}
        wrapperClassName="mb-3 pb-3 border-bottom-1"
        radioClassName="d-flex"
        value={payload?.activityType}
        selected={(e) => updatePayload(e, "activityType")}
      />

      <NavigationButtons
        leftLabel={labels?.startIncome?.back}
        leftClick={back}
        rightLabel={labels?.startIncome?.next}
        rightClick={next}
        leftClassName="col-4"
        rightClassName="col-4"
      />
    </>
  );
};

export default SelectIncomeOption;
