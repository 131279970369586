import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "commonReducer",
  initialState: {
    taxTypesInStore: [],
    selectedTaxTypeInStore: "",
    allocationsInStore: "",
    staticDataInStore: {},
    annuityPayloadInStore: {},
    annuityStatusInStore: {},
    balancesInStore: {},
  },
  reducers: {
    setTaxTypesInStore: (state, action) => {
      state.taxTypesInStore = action.payload;
    },
    setSelectedTaxTypeInStore: (state, action) => {
      state.selectedTaxTypeInStore = action.payload;
    },
    setAllocationsInStore: (state, action) => {
      state.allocationsInStore = action.payload;
    },
    setStaticDataInStore: (state, action) => {
      state.staticDataInStore = action.payload;
    },
    setAnnuityPayloadInStore: (state, action) => {
      state.annuityPayloadInStore = action.payload;
    },
    clearAnnuityPayloadInStore: (state) => {
      state.annuityPayloadInStore = {};
    },
    setAnnuityStatusInStore: (state, action) => {
      state.annuityStatusInStore = action.payload;
    },
    setBalancesInStore: (state, action) => {
      state.balancesInStore = action.payload;
    },
  },
});

export const {
  setTaxTypesInStore,
  setSelectedTaxTypeInStore,
  setAllocationsInStore,
  setStaticDataInStore,
  setAnnuityPayloadInStore,
  clearAnnuityPayloadInStore,
  setAnnuityStatusInStore,
  setBalancesInStore,
} = commonSlice.actions;
export default commonSlice.reducer;
