import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    token: {
      accessToken: "",
      expiresIn: 0,
      refreshExpiresIn: 0,
      refreshToken: "",
      generatedAt: "",
    },
    participantDetails: {
      guestPpt: true,
      firstName: "",
      lastName: "",
      managedAccount: false,
      ownershipId: "",
      plan: "",
      productId: "",
      productSponsorId: "",
      ssn: "",
    },
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTokenGenerated: (state, action) => {
      state.token.generatedAt = action.payload;
    },
    setParticipantDetails: (state, action) => {
      state.participantDetails = action.payload;
    },
  },
});

export const { setToken, setTokenGenerated, setParticipantDetails } =
  authSlice.actions;
export default authSlice.reducer;
