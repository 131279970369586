import Card from "react-bootstrap/Card";
import { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";

const VideoLibrary = ({ labels }) => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState({});

  const handleShow = (e) => {
    setFile(e);
    setShow(true);
  };
  const handleClose = () => {
    setFile({});
    setShow(false);
  };

  const videosArray = [
    {
      fileName:
        "https://players.brightcove.net/1537381467001/USgug6vgPc_default/index.html?videoId=6338865970112",
    },
    {
      fileName:
        "https://players.brightcove.net/1537381467001/USgug6vgPc_default/index.html?videoId=6338866941112",
    },
    {
      fileName:
        "https://players.brightcove.net/1537381467001/USgug6vgPc_default/index.html?videoId=6338865906112",
    },
    {
      fileName:
        "https://players.brightcove.net/1537381467001/USgug6vgPc_default/index.html?videoId=6338866687112",
    },
    {
      fileName:
        "https://players.brightcove.net/1537381467001/USgug6vgPc_default/index.html?videoId=6338867749112",
    },
  ];

  return (
    <>
      <div className="section-two py-2">
        <div className="header mb-4 py-1">
          {labels?.learningCenter?.videoLibrary}
        </div>

        {videosArray.map((e, index) => {
          return (
            <Fragment key={e.fileName}>
              <Card
                style={{
                  width: "399px",
                  height: "225px",
                  marginRight: index % 2 === 0 ? "48px" : "",
                }}
                className="video-card d-inline-block mb-4"
                onClick={() => handleShow(e)}
              >
                <Card.Body className="p-0">
                  <iframe
                    title={`Video-${index}`}
                    src={e.fileName}
                    id="iframeId"
                    style={{
                      width: "399px",
                      height: "225px",
                      pointerEvents: "none",
                    }}
                  ></iframe>
                </Card.Body>
              </Card>
              <small>{e.description}</small>
            </Fragment>
          );
        })}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        id="iframeModal"
        contentClassName="align-items-center"
      >
        <Modal.Body>
          <>
            <iframe
              width="960"
              height="540"
              src={file?.fileName}
              className="d-block"
              title={file?.name}
            ></iframe>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoLibrary;
