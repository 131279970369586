import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { SinglePageLayoutContext } from "layout/SinglePageLayout";
import Button from "common/Button/Button";
import labels from "utils/LocalizedStrings";
import sanitize from "utils/SanitizeHtml";

const SetupAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const singlePageLayoutContext = useContext(SinglePageLayoutContext);

  useEffect(() => {
    if (location?.search) {
      const search = location?.search?.split("=");
      if (Array.isArray(search)) {
        const fromLocation = search[1];

        if (fromLocation === "take-annuity-out-of-plan") {
          singlePageLayoutContext({
            title: labels?.takeAnnuityOutOfPlan?.title,
            buttonText: labels?.takeAnnuityOutOfPlan?.cancel,
            buttonPath: "/dashboard",
            description: labels?.takeAnnuityOutOfPlan?.description,
          });
        } else {
          singlePageLayoutContext({
            title: labels?.startIncome?.title,
            buttonText: labels?.startIncome?.cancel,
            buttonPath: "/dashboard",
            description: "",
          });
        }
      }
    }
  }, []);

  const redirectTo = () => navigate("/dashboard");

  return (
    <section className="single-page-container">
      <div className="header title text-center py-3">
        {labels?.setupAccount?.setupYourAccount}
      </div>
      <p
        className="description mb-0"
        dangerouslySetInnerHTML={sanitize(labels?.setupAccount?.description)}
      ></p>
      <a
        href={labels?.setupAccount?.redirectURL}
        target="_blank"
        rel="noreferrer"
      >
        {labels?.setupAccount?.anchorText}
      </a>
      <div className="d-flex justify-content-center py-3">
        <Button
          wrapperClassName="col-xl-4 col-md-6"
          type="primary"
          text={labels?.setupAccount?.returnToMyDashboard}
          click={redirectTo}
        />
      </div>
    </section>
  );
};

export default SetupAccount;
