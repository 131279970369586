export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function checkFutureDate(value) {
  let now = new Date();
  let today = new Date(now.getYear() + 1900, now.getMonth(), now.getDate());
  let date = new Date(value);
  let dateCompare = new Date(
    date.getYear() + 1900,
    date.getMonth(),
    date.getDate()
  );
  return dateCompare.getTime() > today.getTime();
}

export function formatUSPhone(number) {
  number = number.replace(/\D/g, "");
  if (number.length > 6) {
    return `(${number.substring(0, 3)}) ${number.substring(
      3,
      6
    )}-${number.substring(6)}`;
  } else if (number.length > 3) {
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}`;
  } else {
    return number;
  }
}

export function formatMaskedUSPhone(number) {
  if (number.length > 6) {
    return `(${number.substring(0, 3)}) ${number.substring(
      3,
      6
    )}-${number.substring(6)}`;
  } else if (number.length > 3) {
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}`;
  } else {
    return number;
  }
}

export function amountFormatter(value) {
  if (value === null) {
    return "N/A";
  }
  return parseFloat(value) > 0
    ? "$" +
        Number(value).toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
    : "$0.00";
}

export function percentageFormatter(value) {
  return parseFloat(value) > 0
    ? parseFloat(value * 100).toFixed(2) + "%"
    : "0.00%";
}

export function percentageFormatterForDisplay(value) {
  return parseFloat(value) > 0 ? parseFloat(value).toFixed(2) + "%" : "0.00%";
}

export function openPDF(blob) {
  const fileURL = window.URL.createObjectURL(
    new Blob([blob], { type: "application/pdf" })
  );
  window.open(fileURL, "_blank");
}

export function downloadPDF(blob, fileName) {
  const fileURL = window.URL.createObjectURL(new Blob([blob]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;

  fileLink.setAttribute("download", fileName + ".pdf");
  document.body.appendChild(fileLink);
  fileLink.click();
}

export function getRequiredIds(participantDetails, taxTypeCode, contractId) {
  const obj = {
    productSponsorId: participantDetails?.productSponsorId,
    productId: participantDetails?.productId,
    ownershipId: participantDetails?.ownershipId,
    planId: participantDetails?.extPlanId,
  };
  if (taxTypeCode) obj.taxTypeCode = taxTypeCode;
  if (contractId) obj.contractId = contractId;
  return obj;
}

export function getGender(genderCode) {
  const obj = {
    M: "Male",
    F: "Female",
    O: "Other",
  };

  return obj[genderCode];
}

function replaceNumbers(value) {
  return value.replace(/[^\d[a-z]]/g, "").replace(/\d/g, "X");
}

export function maskSSN(ssn) {
  let val =
    replaceNumbers(ssn.substring(0, 3)) +
    "-" +
    replaceNumbers(ssn.substring(3, 5)) +
    "-" +
    ssn.substr(5);

  return val;
}

export function getStateName(state, data) {
  if (data?.usStateList) {
    const obj = data?.usStateList?.find((e) => e.shortCode === state);
    return obj?.governmentUnitName;
  }
}

export function getStateId(state, data) {
  if (data?.usStateList) {
    const obj = data?.usStateList?.find((e) => e.shortCode === state);
    return obj?.governmentUnitId;
  }
}

export function getCountryName(country, data) {
  if (data?.countryList) {
    const obj = data?.countryList?.find((e) => e.shortCode === country);
    return obj?.governmentUnitName || "US";
  }
}

export function getCountryId(country, data) {
  if (data?.countryList) {
    const obj = data?.countryList?.find((e) => e.shortCode === country);
    return obj?.governmentUnitId;
  }
}

export function getMonthName(month) {
  month = month.replace("0", "");
  return [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];
}

export function getTaxTypeLabel(type) {
  const obj = {
    R: "roth",
    Y: "post-tax",
    N: "pre-tax",
  };
  return obj[type];
}

export async function convertBlobToFile(blobURL, fileName, fileType) {
  try {
    const response = await fetch(blobURL);
    const blob = await response.arrayBuffer();
    const file = new File([blob], fileName, {
      type: fileType,
    });
    return file;
  } catch (err) {
    return "";
  }
}
