import { createContext, useContext, useEffect, useState } from "react";
import Steps from "common/Steps/Steps";
import UpdateAllocations from "./UpdateAllocations";
import ProvideInformation from "./ProvideInformation";
import { useNavigate, useParams } from "react-router";
import { SinglePageLayoutContext } from "layout/SinglePageLayout";
import { useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import labels from "utils/LocalizedStrings";
import Spinner from "common/Spinner/Spinner";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";
import {
  useGetChangeAllocationsQuery,
  usePostChangeAllocationsMutation,
} from "service/APIService";
import { getRequiredIds } from "utils/Utilities";
import { skipToken } from "@reduxjs/toolkit/query";

export const ChangeAllocationsContext = createContext();

const ChangeAllocations = () => {
  const singlePageLayoutContext = useContext(SinglePageLayoutContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [stepTitle, setStepTitle] = useState("");
  const [page, setPage] = useState("");
  const [allocations, setAllocations] = useState([]);
  const [totalInvested, setTotalInvested] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [allocationsReqBody, setAllocationsReqBody] = useState(skipToken);

  const allocationsInStore = useSelector(
    (state) => state.changeAllocationReducer.allocationsInStore
  );
  const selectedTaxTypeInStore = useSelector(
    (state) => state.commonReducer.selectedTaxTypeInStore
  );
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const { data: allocationsData, isFetching } =
    useGetChangeAllocationsQuery(allocationsReqBody);
  const [postChangeAllocationsMutation] = usePostChangeAllocationsMutation();

  useEffect(() => {
    singlePageLayoutContext({
      id: `change-allocations-${id}`,
      title: labels?.changeAnnuityAllocations?.title,
      buttonText: labels?.changeAnnuityAllocations?.cancel,
      buttonPath: "/dashboard",
      description: "",
      popupText: labels?.changeAnnuityAllocations?.returnToMyDashboard,
      confirmationId: confirmationId,
    });

    if (id == 1) {
      setStepTitle(labels?.changeAnnuityAllocations?.updateYourAllocations);
      setPage("Page1");
    } else {
      setStepTitle(labels?.changeAnnuityAllocations?.verifyYourChanges);
      setPage("Page2");
    }
  }, [id, confirmationId]);

  useEffect(() => {
    if (participantDetails?.managedAccount) {
      navigate("/dashboard");
    }

    if (allocationsInStore && allocationsInStore.length) {
      setAllocations(cloneDeep(allocationsInStore));
    }
  }, []);

  useEffect(() => {
    if (selectedTaxTypeInStore) {
      setAllocationsReqBody({
        ...getRequiredIds(participantDetails, selectedTaxTypeInStore),
      });
    }
  }, [selectedTaxTypeInStore]);

  useEffect(() => {
    if (
      allocationsData?.availableAllocationForChanges &&
      !allocationsInStore.length
    ) {
      const data = cloneDeep(allocationsData?.availableAllocationForChanges);
      setAllocations([...data]);
    }
  }, [allocationsData]);

  const submit = () => {
    let newAllocations = cloneDeep(
      allocations.filter((e) => e.reqInvVehicleCurAllocPercent > 0)
    );
    newAllocations.forEach((e) => {
      e.reqInvVehicleCurAllocPercent =
        parseFloat(e.reqInvVehicleCurAllocPercent) / 100;
    });

    setErrors(null);
    setLoading(true);
    postChangeAllocationsMutation({
      ...getRequiredIds(participantDetails, selectedTaxTypeInStore),
      newAllocations: newAllocations,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setConfirmationId("1");
        }
      })
      .catch((err) => {
        setErrors(err?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ChangeAllocationsContext.Provider
      value={{
        allocations,
        setAllocations,
        allocationsData,
        totalInvested,
        setTotalInvested,
        labels,
        setConfirmationId,
        submit,
        participantDetails,
      }}
    >
      <div id="ChangeAllocations">
        {(loading || isFetching) && <Spinner />}
        {errors && (
          <ErrorMessage
            wrapperClassName="row justify-content-center"
            errorClassName="col-md-10"
            error={errors}
          />
        )}
        <Steps
          current={id}
          total="2"
          title={stepTitle}
          description={
            id == 1
              ? labels?.changeAnnuityAllocations?.updateAllocationsDescription
              : ""
          }
        />
        {id == 1 ? <UpdateAllocations /> : <ProvideInformation />}
        <div
          className={`py-4 footer ${
            page === "Page2" && "single-page-container"
          }`}
        >
          {labels?.changeAnnuityAllocations?.footer}
        </div>
      </div>
    </ChangeAllocationsContext.Provider>
  );
};

export default ChangeAllocations;
