import { Navigate, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import MultiPageLayout from "layout/MultiPageLayout";
import SinglePageLayout from "layout/SinglePageLayout";
import { setToken, setTokenGenerated } from "store/authSlice";
import SessionPopup from "common/SessionPopup/SessionPopup";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetNewRefreshTokenQuery } from "service/APIService";
import ReactGA from "react-ga4";

const PrivateRoute = ({ layout, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authReducer?.token);
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const [fetchTokenApi, setFetchTokenApi] = useState(skipToken);
  const { data: newToken, isLoading } =
    useGetNewRefreshTokenQuery(fetchTokenApi);
  const refreshTokenExpiryTime = (token?.refreshExpiresIn - 5 * 60) * 1000;
  const tokenGeneratedTime = token?.generatedAt;

  const [expiryModal, setExpiryModal] = useState(false);
  const [inactiveModal, setInactiveModal] = useState(false);
  const [logoutTime, setLogoutTime] = useState(0);

  let sessionExpiryInterval = "";
  let sessionInactiveTimer = "";
  const sessionInactiveAlert = 1000 * 60 * 5;

  useEffect(() => {
    if (!token?.generatedAt) {
      dispatch(setTokenGenerated(new Date().getTime()));
    }
    const events = ["load", "mousemove", "click", "scroll", "keypress"];
    for (let e in events) {
      window.addEventListener(events[e], setSessionExpiryAlert);
    }

    // Don't allow guest user to any other page
    if (participantDetails && participantDetails?.guestPpt) {
      if (location.pathname !== "/learning-center") {
        navigate("/learning-center");
      }
    }

    return () => {
      for (let e in events) {
        window.removeEventListener(events[e], setSessionExpiryAlert);
      }
    };
  }, []);

  useEffect(() => {
    sessionExpiryInterval = setInterval(handleSessionExpiry, 1000);
  }, [token]);

  useEffect(() => {
    if (newToken) {
      dispatch(setToken(newToken));
      dispatch(setTokenGenerated(new Date().getTime()));
    }
  }, [isLoading]);

  function handleSessionExpiry() {
    if (token?.accessToken && token?.generatedAt) {
      const currentTime = new Date().getTime();
      if (currentTime - tokenGeneratedTime > refreshTokenExpiryTime) {
        setExpiryModal(true);
        setLogoutTime(new Date().getTime() + 5 * 60 * 1000);
        clearInterval(sessionExpiryInterval);
      }
    }
  }

  function continueSession(type) {
    setLogoutTime(0);
    if (type === "sessionExpiry") {
      setFetchTokenApi(1);
      setExpiryModal(false);
    } else {
      setInactiveModal(false);
      clearTimeout(sessionInactiveTimer);
    }
  }

  function exitSession(type) {
    clearTimeout(sessionInactiveTimer);
    dispatch(setToken({}));
    navigate("/", {
      state: {
        authFailed: true,
        message:
          type === "sessionInactive" ? "Session Inactive" : "Session Expired.",
      },
    });
  }

  function setSessionExpiryAlert() {
    if (sessionInactiveTimer) {
      clearTimeout(sessionInactiveTimer);
    }

    sessionInactiveTimer = setTimeout(() => {
      setLogoutTime(new Date().getTime() + 5 * 60 * 1000);
      setInactiveModal(true);
    }, sessionInactiveAlert);
  }

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: location.pathname,
  });

  if (token?.accessToken) {
    return (
      <main className="position-relative">
        <SessionPopup
          type="sessionExpiry"
          showModal={expiryModal}
          primaryButtonClicked={() => continueSession("sessionExpiry")}
          secondaryButtonClicked={() => exitSession("sessionExpiry")}
          logoutTime={logoutTime}
        />

        <SessionPopup
          type="sessionInactive"
          showModal={inactiveModal}
          primaryButtonClicked={() => continueSession("sessionInactive")}
          secondaryButtonClicked={() => exitSession("sessionInactive")}
          logoutTime={logoutTime}
        />
        {layout === "MultiPageLayout" && (
          <MultiPageLayout
            guestUser={participantDetails && participantDetails?.guestPpt}
          >
            {children}
          </MultiPageLayout>
        )}
        {layout === "SinglePageLayout" && (
          <SinglePageLayout>{children}</SinglePageLayout>
        )}
      </main>
    );
  } else {
    return (
      <Navigate to="/" state={{ authFailed: true, message: "Not Allowed" }} />
    );
  }
};

export default PrivateRoute;
