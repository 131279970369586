import LearnMore from "./LearnMore";
import Resources from "./Resources";
import VideoLibrary from "./VideoLibrary";
import labels from "utils/LocalizedStrings";
import SectionFooter from "common/Footer/SectionFooter";
import { useState, useEffect } from "react";
import Spinner from "common/Spinner/Spinner";
import { APIEndpoints } from "utils/APIEndpoints";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";

const LearningCenter = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [resources, setResources] = useState([]);

  const getResources = async () => {
    const tenant = localStorage.getItem("tenant");
    setLoading(true);
    try {
      const response = await fetch(APIEndpoints.resources(tenant));
      const data = await response.json();
      setResources(data);
    } catch (err) {
      setErrors(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <main id="learning-center">
      {loading && <Spinner />}
      {errors && !loading && <ErrorMessage error={errors} />}
      <section className="dashboard-layout shadow-lg row py-4">
        <Resources labels={labels} resources={resources} />
        <VideoLibrary labels={labels} />
        <LearnMore labels={labels} />
      </section>
      <SectionFooter page="learningCenter" />
    </main>
  );
};

export default LearningCenter;
