const Row = ({ leftValue, rightValue, noBorder, children }) => {
  return (
    <div
      className={`flex-between-center ${
        noBorder ? "py-1" : "border-bottom-1 py-3"
      }`}
    >
      <div className="left-value">{leftValue}</div>
      {rightValue && <div className="right-value">{rightValue}</div>}
      {children}
    </div>
  );
};

export default Row;
