import { Children } from "react";

const RowTable = ({
  title,
  description,
  children,
  wrapperClassName,
  rowClassName,
}) => {
  let _header,
    _total = "";
  let _body = [];

  Children.forEach(children, (child) => {
    if (child.key === "row-header") {
      return (_header = child);
    }

    if (child.key === "row-body") {
      return (_body = child);
    }

    if (child.key === "row-total") {
      return (_total = child);
    }
  });
  return (
    <section className={`row-table ${rowClassName}`}>
      {title && (
        <div className="border-bottom-1-dark py-2">
          <div className="title header">{title}</div>
        </div>
      )}

      {description && <p className="mb-0">{description}</p>}

      <div className={`investment-box mx-0 my-3 ${wrapperClassName}`}>
        <div className="flex-between-center px-4 investment-box-header">
          {_header}
        </div>

        {Array.isArray(_body.props.children) ? (
          _body?.props?.children.map((e, index) => (
            <div
              className="flex-between-center py-2 px-4 investment-box-body flex-wrap"
              key={index}
            >
              {e}
            </div>
          ))
        ) : (
          <div className="flex-between-center py-2 px-4 investment-box-body flex-wrap">
            {_body}
          </div>
        )}

        {_total && (
          <div className="flex-between-center investment-box-total py-2 px-4">
            {_total}
          </div>
        )}
      </div>
    </section>
  );
};

export default RowTable;
