import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { useState, useEffect } from "react";

const Titlebar = ({ showName, showPlan, showNotifications, guestUser }) => {
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const selectedTaxTypeInStore = useSelector(
    (state) => state?.commonReducer?.selectedTaxTypeInStore
  );
  const contractStatus = useSelector(
    (state) => state?.commonReducer?.annuityStatusInStore
  );
  const navigate = useNavigate();
  const openNotifications = () => navigate("/notifications");
  const checkOneMonthDiff = () => {
    const dob = participantDetails.dob;
    if (dob) {
      const dobDate = dob.split("/");
      const current = dayjs().format("MM/DD/YYYY").split("/");

      const original = dayjs(dobDate[0] + "/" + dobDate[1] + "/" + current[2]);
      const diff = original.diff(dayjs().format("MM/DD/YYYY"), "day");
      return diff <= 30 && diff >= 1;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (contractStatus) {
      setDisplayNotifications(
        (["NONE", "REJECTED"].indexOf(contractStatus?.annuityStatus) > -1 &&
          contractStatus?.contractStatus === "VALID" &&
          checkOneMonthDiff()) ||
          ["PENDING", "APPROVED"].indexOf(contractStatus?.annuityStatus) > -1 ||
          ["PENDING", "APPROVED"].indexOf(contractStatus?.contractStatus) > -1
      );
    }
  }, [contractStatus]);

  return (
    <section className="row titlebar">
      <div className="flex-between-center titleBody p-0">
        {showName && (
          <div className="participant-name">
            {participantDetails?.firstName} {participantDetails?.middleName}{" "}
            {participantDetails?.lastName}
          </div>
        )}

        {showPlan && (
          <div className="show-plan">
            <span className="plan-name me-3">
              {participantDetails?.planName}
            </span>
            {showNotifications &&
              !guestUser &&
              selectedTaxTypeInStore &&
              displayNotifications && (
                <>
                  <button
                    type="button"
                    className="notification btn position-relative"
                    onClick={openNotifications}
                  >
                    <MaterialIcon
                      iconName="notifications"
                      iconClassName="notification-icon"
                    />
                    <span className="position-absolute badge bg-danger notification-number">
                      1
                    </span>
                  </button>
                </>
              )}
          </div>
        )}
      </div>
    </section>
  );
};

Titlebar.defaultProps = {
  showName: true,
  showPlan: true,
  showNotifications: true,
};

export default Titlebar;
