import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "styles.scss";
import App from "App";
import store from "./store/store";
import strings from "./utils/LocalizedStrings";
import { APIEndpoints } from "utils/APIEndpoints";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

async function getLabelsAndConfig() {
  const tenantName = window.location.pathname.split("/")[1];
  let client = process.env.PUBLIC_URL || tenantName;
  client = client.replace("/", "");
  localStorage.setItem("tenant", client);

  const data = await fetch(APIEndpoints.labels(client));
  await setStyleSheet(APIEndpoints.styles(client));
  await getAPIKey();
  return data.json();
}

async function setStyleSheet(path) {
  const head = window.document.getElementsByTagName("head")[0];
  const style = window.document.createElement("link");

  if (style) {
    style.href = path;
    style.rel = "stylesheet";
    style.type = "text/css";
    head.appendChild(style);
  }
}

async function getAPIKey() {
  const env = process.env.REACT_APP_NODE_ENV || "dev";
  const analyticsID = env + "AnalyticsID";

  const config = await fetch(
    APIEndpoints.clientConfig(localStorage.getItem("tenant"))
  );
  const clientConfig = await config.json();
  sessionStorage.setItem("apiKey", clientConfig.apiKey);
  sessionStorage.setItem("analyticsId", clientConfig[analyticsID]);
}

// ReactGA.initialize([
//   {
//     trackingId: getAnalyticsId(),
//     testMode: true,
//     gaOptions: {
//       testMode: true,
//     },
//   },
// ]);

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

function render() {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}

getLabelsAndConfig()
  .then((res) => {
    strings.setContent({
      en: res || {},
    });
    render();
  })
  .catch(() => {
    render();
  });
