import { NavLink, useNavigate } from "react-router-dom";
import { APIEndpoints } from "utils/APIEndpoints";
import labels from "utils/LocalizedStrings";
import sanitize from "utils/SanitizeHtml";

const Navbar = ({ guestUser, layout, data, click }) => {
  const navigate = useNavigate();
  const navigationList = [
    {
      name: labels?.navbar?.dashboard,
      path: "/dashboard",
    },
    {
      name: labels?.navbar?.documents,
      path: "/documents",
    },
    {
      name: labels?.navbar?.learningCenter,
      path: "/learning-center",
    },
  ];

  const buttonClick = () => (data?.closeButton ? navigate(-1) : click());

  if (layout === "singlePageLayout") {
    return (
      <section className="row navbar align-items-center">
        <nav className="d-flex flex-column justify-content-center">
          <div className="text-center">
            <span>{data?.title}</span>
            <button className="cancelButton" onClick={() => buttonClick()}>
              {" "}
              {data?.buttonText}
            </button>
          </div>
          {data?.description && (
            <p className="text-center mb-0 description">{data?.description}</p>
          )}
        </nav>
      </section>
    );
  } else {
    return (
      <>
        {guestUser && (
          <section className="guest-image">
            <img
              src={APIEndpoints.images(
                localStorage.getItem("tenant"),
                "guestImage.jpg"
              )}
              alt="Guest"
              width="100%"
              height="auto"
            />
          </section>
        )}

        <section className="row navbar align-items-center">
          <nav
            className={`p-0 d-flex justify-content-center align-items-center desktop ${
              guestUser && "guest"
            }`}
          >
            <div
              className="col-md-6 title"
              dangerouslySetInnerHTML={sanitize(labels?.navbar?.title)}
            ></div>

            {!guestUser && (
              <nav className="col-md-6 mb-0 text-end">
                {navigationList.map((e) => {
                  return (
                    <NavLink
                      key={e.name}
                      to={e.path}
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                    >
                      {e.name}
                    </NavLink>
                  );
                })}
              </nav>
            )}
          </nav>

          <nav className="mobile px-0">
            <div
              className="col-12 title"
              dangerouslySetInnerHTML={sanitize(labels?.navbar?.title)}
            ></div>

            {!guestUser && (
              <nav className="col-12 py-2">
                {navigationList.map((e) => {
                  return (
                    <NavLink
                      key={e.name}
                      to={e.path}
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                    >
                      {e.name}
                    </NavLink>
                  );
                })}
              </nav>
            )}
          </nav>
        </section>
      </>
    );
  }
};

export default Navbar;
