import ArrowLink from "common/ArrowLink/ArrowLink";
import SelectDropdown from "common/SelectDropdown/SelectDropdown";
import { useContext, useEffect } from "react";
import { DashboardContext } from "./Dashboard";
import labels from "utils/LocalizedStrings";

const SectionOne = () => {
  const { taxTypes, selectedTaxType, setSelectedTaxType } =
    useContext(DashboardContext);

  useEffect(() => {}, []);

  return (
    <div className="section-one flex-between-center border-bottom-1 mb-4 pb-3 flex-wrap">
      <SelectDropdown
        wrapperClassName="col-xl-4 col-md-6 col-12"
        selectClassName="w-100"
        dropdownData={taxTypes}
        value={selectedTaxType}
        select={(value) => setSelectedTaxType(value)}
        optionLabel="taxTypeName"
        optionId="taxTypeCode"
        ariaLabel="Tax Types Dropdown"
      />
      <div className="glossary-terms">
        <ArrowLink
          link={labels?.dashboard?.glossaryTermsHref}
          text={labels?.dashboard?.glossaryTerms}
          className="justify-content-center"
        />
      </div>
    </div>
  );
};

export default SectionOne;
