import Modal from "react-bootstrap/Modal";
import Button from "common/Button/Button";
import MaterialIcon from "common/MaterialIcon/MaterialIcon";

const SuccessPopup = ({
  showModal,
  setShowModal,
  showHeader,
  showFooter,
  click,
  buttonText,
  confirmationId,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="success-popup"
      backdrop="static"
      keyboard={false}
    >
      {showHeader && (
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Header</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="text-center pt-5">
        <MaterialIcon iconName="check_circle" iconClassName="display-4" />
        <div className="title">Success</div>
        <div className="body">
          Your changes have been submitted.
          <br />
          {/* Confirmation : {confirmationId} */}
        </div>
        <Button
          type="primary"
          text={buttonText}
          wrapperClassName="py-4"
          click={() => click()}
          ariaLabel={buttonText}
        />
      </Modal.Body>
      {showFooter && <Modal.Footer>Footer</Modal.Footer>}
    </Modal>
  );
};

export default SuccessPopup;
