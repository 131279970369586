import labels from "utils/LocalizedStrings";

const year = new Date().getFullYear();
const Footer = () => {
  return (
    <footer>
      <div className="row py-2">
        <div className="col-md-6">
          <small>
            &copy; {year} {labels?.footer?.copyright}
          </small>
        </div>

        <div className="col-md-6 text-end footer-questions">
          <small>
            {labels?.footer?.questionsAboutAnnuity} <br />
            {labels?.footer?.callClientServices}
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
