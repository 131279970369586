import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import useEventTracker from "common/Tracker/EventTracker";
// Type - Primary (Primary Color background with white text)
// Type - Secondary (White background with Primary Color text)
// Type - Make Changes (Edit Icon inside the button, white background, primary color text)

const Button = ({
  wrapperClassName,
  buttonClassName,
  type,
  text,
  click,
  disabled,
  id,
  ariaLabel,
}) => {
  const gaEventTracker = useEventTracker({});
  return (
    <div className={`common-button ${wrapperClassName}`}>
      <button
        className={`form-control py-2 ${type} ${buttonClassName}`}
        onClick={() => {
          gaEventTracker("Button", `Clicked on ${text}`, ariaLabel);
          click();
        }}
        disabled={disabled}
        id={id}
        aria-label={ariaLabel}
      >
        {type === "makeChanges" && <MaterialIcon iconName="edit" />} {text}
      </button>
    </div>
  );
};

export default Button;
Button.defaultProps = {
  disabled: false,
  click: () => {},
  wrapperClassName: "",
  buttonClassName: "",
  id: "",
};
