import { createSlice } from "@reduxjs/toolkit";

export const changeAllocationSlice = createSlice({
  name: "changeAllocationReducer",
  initialState: {
    allocationsInStore: []
  },
  reducers: {
    setAllocationsInStore: (state, action) => {
      state.allocationsInStore = action.payload;
    },
  
    clearAllocationsInStore: (state) => {
      state.allocationsInStore = [];
    },
  },
});

export const {
  setAllocationsInStore,
  clearAllocationsInStore,
} = changeAllocationSlice.actions;
export default changeAllocationSlice.reducer;
