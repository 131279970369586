import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeAllocationsContext } from "./ChangeAllocations";
import InputField from "common/InputField/InputField";
import sumBy from "lodash/sumBy";
import { useDispatch } from "react-redux";
import { setAllocationsInStore } from "store/changeAllocationSlice";
import {
  amountFormatter,
  percentageFormatter,
  percentageFormatterForDisplay,
} from "utils/Utilities";
import cloneDeep from "lodash/cloneDeep";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import ArrowLink from "common/ArrowLink/ArrowLink";

const UpdateAllocations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    allocations,
    setAllocations,
    allocationsData,
    totalInvested,
    setTotalInvested,
    labels,
  } = useContext(ChangeAllocationsContext);
  const [hideRequestedAllocation, setHideRequestedAllocations] =
    useState(false);

  const next = () => {
    dispatch(setAllocationsInStore(allocations));
    navigate("/change-allocations/2");
  };
  const back = () => navigate("/dashboard");
  const newInvestmentAllocation = (value, index) => {
    const allocation = cloneDeep(allocations);
    allocation[index].reqInvVehicleCurAllocPercent = value;
    setAllocations([...allocation]);
  };
  const checkNextDisabled = () => {
    const total = sumBy(allocations, (e) =>
      parseInt(e.reqInvVehicleCurAllocPercent)
    );
    return parseInt(total) !== 100;
  };

  useEffect(() => {
    const total = sumBy(allocations, (e) =>
      parseFloat(e.reqInvVehicleCurAllocPercent)
    );
    setTotalInvested(total.toFixed(2));

    const difference = allocations.filter(
      (e) => e.currentAllocationPt !== e.requestedAllocationPt
    );
    setHideRequestedAllocations(difference && !difference.length);
  }, [allocations]);

  return (
    <>
      <p>{labels?.changeAnnuityAllocations?.updateAllocationsDescription1}</p>
      <div className="flex-between-center justify-content-end mb-2">
        <ArrowLink
          link={labels?.dashboard?.annuityAllocationGuideHref}
          text={labels?.dashboard?.annuityAllocationGuide}
          className="mb-2"
          wrapperClassName="col-4 col-md-2 d-flex justify-content-end"
        />
        <div className="col-4 col-md-2 invest-label">
          {labels?.changeAnnuityAllocations?.youNeedToInvest}
          <br /> <span className="ready-to-invest">100%</span>
        </div>
        <div className="col-4 col-md-2 invest-label">
          {labels?.changeAnnuityAllocations?.youHaveInvested} <br />
          <span className="invested">
            {percentageFormatterForDisplay(totalInvested)}
          </span>
        </div>
      </div>

      <section className="desktop">
        <div className="table-header d-flex justify-content-between align-items-end">
          <div className="col-md-4">
            {labels?.changeAnnuityAllocations?.name}
          </div>
          <div className="col-md-2 text-end">
            {labels?.changeAnnuityAllocations?.currentAccumulationBalance}
          </div>
          <div className="col-md-2 text-end">
            {labels?.changeAnnuityAllocations?.currentAllocations}
          </div>
          <div className="col-md-2 text-end">
            {allocationsData?.reqAllocAsOfDt
              ? labels?.changeAnnuityAllocations?.requestAllocationsAsOf
              : labels?.changeAnnuityAllocations?.requestAllocations}
            &nbsp;
            {allocationsData?.reqAllocAsOfDt}
          </div>
          <div className="col-md-2 text-end">
            {labels?.changeAnnuityAllocations?.newInvestmentAllocations}
          </div>
        </div>

        {allocations.map((e, index) => {
          return (
            <div
              className="table-body flex-between-center py-3"
              key={e.isrInvestmentVehicleId}
            >
              <div className="col-md-4 first">{e.isrInvestmentVehicleName}</div>
              <div className="col-md-2 text-end">
                {amountFormatter(e.currentAccumulationAmount)}
              </div>
              <div className="col-md-2 text-end">
                {percentageFormatter(e.currentAllocationPt)}
              </div>
              <div className="col-md-2 text-end">
                {hideRequestedAllocation
                  ? "0%"
                  : percentageFormatter(e.requestedAllocationPt)}
              </div>
              <div className="col-md-2 d-flex justify-content-end align-items-center p-0 last">
                <InputField
                  type="number"
                  inputClass="mx-1"
                  percentage={true}
                  wrapperClassName="d-flex align-items-center justify-content-end"
                  min="1"
                  max="100"
                  value={e.reqInvVehicleCurAllocPercent}
                  blur={(val) => newInvestmentAllocation(val, index)}
                  step="0.01"
                  decimal={false}
                  ariaLabel={`Allocation Percentage For Index ${index}`}
                />
              </div>
            </div>
          );
        })}
      </section>

      <section className="mobile border-1 border-bottom-0">
        {allocations.map((e, index) => {
          return (
            <div
              className="flex-between-center flex-wrap p-2 mb-2 border-bottom-1"
              key={e.isrInvestmentVehicleId}
            >
              <div className="col-4 py-1">
                {labels?.changeAnnuityAllocations?.name}
              </div>
              <div className="col-8 first text-end">
                {e.isrInvestmentVehicleName}
              </div>

              <div className="col-6 py-1">
                {labels?.changeAnnuityAllocations?.currentAccumulationBalance}
              </div>
              <div className="col-6 text-end">
                {amountFormatter(e.currentAccumulationAmount)}
              </div>

              <div className="col-6 py-1">
                {labels?.changeAnnuityAllocations?.currentAllocations}
              </div>
              <div className="col-6 text-end">
                {percentageFormatter(e.currentAllocationPt)}
              </div>

              <div className="col-6 py-1">
                {allocationsData?.reqAllocAsOfDt
                  ? labels?.changeAnnuityAllocations?.requestAllocationsAsOf
                  : labels?.changeAnnuityAllocations?.requestAllocations}
                &nbsp;
                {allocationsData?.reqAllocAsOfDt}
              </div>
              <div className="col-6 text-end">
                {hideRequestedAllocation
                  ? "0%"
                  : percentageFormatter(e.requestedAllocationPt)}
              </div>

              <div className="col-6 py-1">
                {labels?.changeAnnuityAllocations?.newInvestmentAllocations}
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center p-0 last">
                <InputField
                  type="number"
                  inputClass="mx-1"
                  percentage={true}
                  wrapperClassName="d-flex align-items-center justify-content-end"
                  min="1"
                  max="100"
                  value={e.reqInvVehicleCurAllocPercent}
                  blur={(val) => newInvestmentAllocation(val, index)}
                  step="0.01"
                  decimal={false}
                  ariaLabel={`Allocation Percentage For Index ${index}`}
                />
              </div>
            </div>
          );
        })}
      </section>

      <NavigationButtons
        wrapperClassName="border-bottom-1 py-5"
        leftLabel={labels?.changeAnnuityAllocations?.back}
        leftClick={back}
        leftClassName="col-4 col-sm-3 col-md-2"
        rightLabel={labels?.changeAnnuityAllocations?.next}
        rightClassName="col-4 col-sm-3 col-md-2"
        rightClick={next}
        rightDisabled={checkNextDisabled()}
      />
    </>
  );
};

export default UpdateAllocations;
