import Modal from "react-bootstrap/Modal";
import Button from "common/Button/Button";
import { useEffect, useState } from "react";

const SessionPopup = ({
  type,
  showModal,
  setShowModal,
  logoutTime,
  primaryButtonClicked,
  secondaryButtonClicked,
}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const calculateTimeLeft = () => {
    let now = new Date().getTime();
    let difference = logoutTime - now;
    if (difference > 0) {
      setMinutes(Math.floor((difference / (60 * 1000)) % 60));
      setSeconds(Math.floor((difference / 1000) % 60));
    }
    if (difference < 0) {
      secondaryButtonClicked();
    }
  };

  function handleLogoutButton() {
    if (minutes > 0 || seconds > 0) {
      return `Logout (${minutes}:${seconds})`;
    }
    return "Logout";
  }

  useEffect(() => {
    let interval = 0;
    if (showModal) {
      interval = setInterval(calculateTimeLeft, 1000);
    } else {
      clearInterval(interval);
      setMinutes(0);
      setSeconds(0);
    }

    return () => clearInterval(interval);
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="session-popup"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="title">
          {type === "sessionExpiry" ? "Session Expired!" : "Session Inactive!"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <div>
          {type === "sessionExpiry"
            ? "Your session is going to expire. Click on continue if you want to extend the session."
            : "You have been inactive from the last few minutes. Click on continue to resume the session."}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            text={handleLogoutButton()}
            wrapperClassName="py-4 col-md-5 me-2"
            click={() => secondaryButtonClicked()}
          />
          <Button
            type="primary"
            text="Continue"
            wrapperClassName="py-4 col-md-5"
            click={() => primaryButtonClicked()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SessionPopup;
