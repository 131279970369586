import { useEffect, useState } from "react";
import { useGetTokenQuery } from "service/APIService";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Spinner from "common/Spinner/Spinner";
import { setParticipantDetails, setToken } from "store/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [fetchTokenApi, setFetchTokenApi] = useState(skipToken);

  const { data: apiData, isLoading, error } = useGetTokenQuery(fetchTokenApi);

  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (location?.state && location?.state?.authFailed) {
      setErrors(location?.state?.message);
      navigate("/", { replace: true });
      return;
    } else {
      setFetchTokenApi(4);
    }
  }, []);

  useEffect(() => {
    if (apiData && apiData?.token?.accessToken) {
      dispatch(setToken(apiData?.token));
      dispatch(setParticipantDetails(apiData?.participantInfo));

      if (apiData?.participantInfo?.guestPpt) {
        navigate("/learning-center");
      } else {
        navigate("/dashboard");
      }
    }
  }, [apiData]);

  return (
    <div className="text-center">
      {isLoading && <Spinner></Spinner>}
      {(error || errors) && (
        <>
          <h1>UNAUTHORIZED</h1>
          {error && (
            <ErrorMessage
              error={error?.data}
              wrapperClassName="col-md-8 mx-auto"
            />
          )}
          <h2>{errors}</h2>
        </>
      )}
    </div>
  );
};

export default Home;
