import Button from "common/Button/Button";
import InputField from "common/InputField/InputField";
import { useEffect, useState } from "react";
import { useFetchTestTokenMutation } from "service/APIService";
import { useDispatch } from "react-redux";
import { setParticipantDetails, setToken } from "store/authSlice";
import { useNavigate } from "react-router";
import Spinner from "common/Spinner/Spinner";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";
import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import { Constants } from "utils/Constants";

const TestPage = () => {
  const [testPagePayload, setTestPagePayload] = useState({
    ssn: "",
    planId: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showTestPage, setShowTestPage] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = () => {
    if (testPagePayload?.ssn === "") {
      setError({
        errorMessage: "SSN is missing.",
      });
      return;
    }

    if (testPagePayload?.planId === "") {
      setError({
        errorMessage: "Plan ID is missing.",
      });
      return;
    }

    if (testPagePayload?.ssn?.length !== 9) {
      setError({
        errorMessage: "SSN must be 9 digits.",
      });
      return;
    }

    setLoading(true);
    setError(null);
    testPageMutation(testPagePayload)
      .unwrap()
      .then((res) => {
        dispatch(setToken(res?.token));
        dispatch(setParticipantDetails(res?.participantInfo));

        if (res?.participantInfo?.guestPpt) {
          navigate("/learning-center");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setError(err?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [testPageMutation] = useFetchTestTokenMutation();

  useEffect(() => {
    sessionStorage.removeItem("persist:root");
    if (
      [
        Constants.PROD_UI_URL,
        Constants.PROD_UI_KC_URL,
        Constants.PROD_UI_STL_URL,
      ].indexOf(window.location.hostname) > -1
    ) {
      setShowTestPage(false);
    }
  }, []);

  if (showTestPage) {
    return (
      <section className="p-4 position-relative" style={{ height: "100vh" }}>
        {loading && <Spinner />}
        {error && <ErrorMessage error={error} wrapperClassName="mb-2" />}
        <InputField
          type="number"
          label="SSN"
          wrapperClassName="col-md-2 mb-3"
          value={testPagePayload.ssn}
          id="SSN"
          ariaLabel="Participant SSN"
          blur={(e) =>
            setTestPagePayload({
              ...testPagePayload,
              ssn: e,
            })
          }
        />
        <InputField
          type="text"
          label="Plan ID"
          id="Plan-ID"
          wrapperClassName="col-md-2 mb-3"
          value={testPagePayload.planId}
          ariaLabel="Participant Plan ID"
          blur={(e) =>
            setTestPagePayload({
              ...testPagePayload,
              planId: e,
            })
          }
        />
        <Button
          type="primary"
          text="Submit"
          wrapperClassName="col-md-1"
          click={submit}
          ariaLabel="Submit Button"
        />
        <MaterialIcon iconName="key" wrapperClassName="visually-hidden" />
      </section>
    );
  } else {
    return <div className="text-center h2 pt-3">UNAUTHORIZED</div>;
  }
};

export default TestPage;
