import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import SelectDropdown from "common/SelectDropdown/SelectDropdown";
import {
  useGetBalancesQuery,
  useGetStatementsQuery,
  useLazyGetStatementAsPDFQuery,
} from "service/APIService";
import labels from "utils/LocalizedStrings";
import RowHeader from "common/RowHeader/RowHeader";
import { useEffect, useState } from "react";
import { getRequiredIds, scrollToTop } from "utils/Utilities";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";

const Statements = ({
  setLoading,
  setError,
  participantDetails,
  staticData,
}) => {
  const [selectedTaxType, setSelectedTaxType] = useState("");
  const [payload, setPayload] = useState(skipToken);
  const [statementsPayload, setStatementsPayload] = useState(skipToken);
  const taxTypes = useSelector(
    (state) => state?.commonReducer?.taxTypesInStore
  );

  const [getPDF, { isFetching: loading, error }] =
    useLazyGetStatementAsPDFQuery();
  const { data: balances, isFetching: loading2 } = useGetBalancesQuery(payload);
  const { data: statements, isFetching: loading1 } =
    useGetStatementsQuery(statementsPayload);

  const viewAsPDF = (year, contractId) => {
    getPDF({ year: year, contractId: contractId, open: true });
  };
  const downloadAsPDF = (year, contractId) => {
    getPDF({ year: year, contractId: contractId, open: false });
  };

  const getContractName = (code) => {
    return taxTypes?.find((e) => e.taxTypeCode == code)?.taxTypeName;
  };

  useEffect(() => {
    if (taxTypes && taxTypes?.length) {
      setSelectedTaxType(taxTypes[0]?.taxTypeCode);
    }
  }, []);

  useEffect(() => {
    if (selectedTaxType) {
      setPayload(getRequiredIds(participantDetails, selectedTaxType));
    }
  }, [selectedTaxType]);

  useEffect(() => {
    if (selectedTaxType && balances) {
      setStatementsPayload(
        getRequiredIds(participantDetails, null, balances?.contractId)
      );
    }
  }, [balances]);

  useEffect(() => {
    setLoading(loading || loading1 || loading2);
    if (error) {
      setError({ ...error, type: "statementError" });
      scrollToTop();
    } else {
      setError(null);
    }
  }, [loading, error, loading1, loading2]);

  return (
    <div className="section-two py-2">
      <RowHeader
        header={labels?.documents?.statements}
        wrapperClassName="mb-3 border-bottom-1"
      />

      <div className="flex-between-center pb-3 mb-4 border-bottom-1 flex-wrap">
        <SelectDropdown
          wrapperClassName="col-xl-4 col-md-6 col-12"
          selectClassName="w-100"
          dropdownData={taxTypes}
          value={selectedTaxType}
          select={(value) => setSelectedTaxType(value)}
          optionLabel="taxTypeName"
          optionId="taxTypeCode"
          ariaLabel="Tax Types Dropdown"
        />

        <div className="as-of-date">
          <small>
            {labels?.documents?.asOf} {staticData?.prevBusinessDate}
          </small>
        </div>
      </div>

      <div className="mb-4 selected">
        <div className="title mb-2">{getContractName(selectedTaxType)}</div>
        {balances?.contractId && (
          <div className="mb-1">
            {labels?.documents?.contract} {balances?.contractId}
          </div>
        )}
        {balances?.contractDate && (
          <div>
            {labels?.documents?.contractDate}: {balances?.contractDate}
          </div>
        )}
      </div>

      {Array.isArray(statements) && statements.length > 0 ? (
        <>
          <section className="desktop">
            <div className="d-flex justify-content-between columns mb-2 py-1">
              <h6 className="col-md-2 sub-header">{labels?.documents?.year}</h6>
              <h6 className="col-md-4 sub-header">
                {labels?.documents?.annuityName}
              </h6>
              <h6 className="col-md-2 sub-header">
                {labels?.documents?.taxPlanType}
              </h6>
              <h6 className="col-md-3"> </h6>
            </div>
            {statements.map((e, index) => (
              <div
                className={`d-flex justify-content-between py-2 my-2 ${
                  index == statements.length - 1 ? "" : "border-bottom-1"
                }`}
                key={e.statementId}
              >
                <div className="col-md-2 sub-entity">{e.statementYear}</div>
                <div
                  className="col-md-4 sub-entity"
                  // dangerouslySetInnerHTML={sanitize(
                  //   labels?.documents?.[e.annuityName]
                  // )}
                >
                  {e.annuityName}
                </div>
                <div className="col-md-2 sub-entity">{e.taxPlanType}</div>
                <div className="col-md-3 d-flex justify-content-end">
                  <MaterialIcon
                    iconName="visibility"
                    wrapperClassName="border-right-1 pe-2"
                    click={() =>
                      viewAsPDF(e.statementYear, balances?.contractId)
                    }
                  />
                  <MaterialIcon
                    iconName="download"
                    wrapperClassName="ps-2"
                    click={() =>
                      downloadAsPDF(e.statementYear, balances?.contractId)
                    }
                  />
                </div>
              </div>
            ))}
          </section>

          <section className="mobile">
            <section className="d-flex flex-wrap border-1 p-2">
              {statements.map((e) => {
                return (
                  <>
                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.year}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {e.statementYear}
                    </div>

                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.annuityName}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {e.annuityName}
                    </div>

                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.taxPlanType}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {e.taxPlanType}
                    </div>

                    <div className="col-6 sub-header py-1">View/Download</div>
                    <div className="col-6 d-flex justify-content-end mb-4">
                      <MaterialIcon
                        iconName="visibility"
                        wrapperClassName="border-right-1 pe-2"
                        click={() =>
                          viewAsPDF(e.statementYear, balances?.contractId)
                        }
                      />
                      <MaterialIcon
                        iconName="download"
                        wrapperClassName="ps-2"
                        click={() =>
                          downloadAsPDF(e.statementYear, balances?.contractId)
                        }
                      />
                    </div>
                  </>
                );
              })}
            </section>
          </section>
        </>
      ) : (
        <div className="">{labels?.documents?.noStatements}</div>
      )}
    </div>
  );
};

export default Statements;
