import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import {
  useGetContractsQuery,
  useLazyGetContractAsPDFQuery,
} from "service/APIService";
import labels from "utils/LocalizedStrings";
import RowHeader from "common/RowHeader/RowHeader";
import { useEffect } from "react";
import { getRequiredIds } from "utils/Utilities";

const Contracts = ({ setLoading, setError, participantDetails }) => {
  const [getPDF, { isFetching: loading1, error }] =
    useLazyGetContractAsPDFQuery();
  const { data: contracts, isFetching: loading } = useGetContractsQuery(
    getRequiredIds(participantDetails)
  );

  const viewAsPDF = (contractId) => {
    getPDF({ contractId: contractId, open: true });
  };

  const downloadAsPDF = (contractId) => {
    getPDF({ contractId: contractId, open: false });
  };

  useEffect(() => {
    setLoading(loading || loading1);
    if (error) {
      setError({ ...error, type: "contractError" });
    } else {
      setError(null);
    }
  }, [loading, loading1, error]);

  return (
    <div className="section-one py-2 mb-4">
      <RowHeader
        header={labels?.documents?.contracts}
        wrapperClassName="mb-3 border-bottom-1"
      />

      {Array.isArray(contracts) && contracts.length ? (
        <>
          <section className="desktop">
            <div className="d-flex justify-content-between columns py-2">
              <div className="col-md-3 sub-header">
                {labels?.documents?.contractNumbers}
              </div>
              <div className="col-md-2 sub-header">
                {labels?.documents?.contractDate}
              </div>
              <div className="col-md-2 sub-header">
                {labels?.documents?.planType}
              </div>
              <div className="col-md-2 sub-header">
                {labels?.documents?.moneyType}
              </div>
              <div className="col-md-3"> </div>
            </div>
            {contracts.map((e, index) => (
              <div
                className={`d-flex justify-content-between py-2 my-2 ${
                  index == contracts.length - 1 ? "" : "border-bottom-1"
                }`}
                key={e.contractNumber}
              >
                <div className="col-md-3 sub-entity">{e.contractNumber}</div>
                <div className="col-md-2 sub-entity">{e.contractDate}</div>
                <div className="col-md-2 sub-entity">{e.planType}</div>
                <div className="col-md-2 sub-entity">
                  {labels?.documents?.[e.moneyType]}
                </div>
                <div className="col-md-3 d-flex justify-content-end">
                  <MaterialIcon
                    iconName="visibility"
                    wrapperClassName="border-right-1 pe-2"
                    click={() => viewAsPDF(e.contractNumber)}
                  />
                  <MaterialIcon
                    iconName="download"
                    wrapperClassName="ps-2"
                    click={() => downloadAsPDF(e.contractNumber)}
                  />
                </div>
              </div>
            ))}
          </section>
          <section className="mobile">
            <section className="d-flex flex-wrap border-1 p-2">
              {contracts.map((e) => {
                return (
                  <>
                    <div className="col-6 sub-header py-1">Contract Number</div>
                    <div className="col-6 sub-entity text-end">
                      {e.contractNumber}
                    </div>

                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.contractDate}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {e.contractDate}
                    </div>

                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.planType}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {e.planType}
                    </div>

                    <div className="col-6 sub-header py-1">
                      {labels?.documents?.moneyType}
                    </div>
                    <div className="col-6 sub-entity text-end">
                      {labels?.documents?.[e.moneyType]}
                    </div>

                    <div className="col-6 sub-header py-1">View/Download</div>
                    <div className="col-6 d-flex justify-content-end mb-4">
                      <MaterialIcon
                        iconName="visibility"
                        wrapperClassName="border-right-1 pe-2"
                        click={() => viewAsPDF(e.contractNumber)}
                      />
                      <MaterialIcon
                        iconName="download"
                        wrapperClassName="ps-2"
                        click={() => downloadAsPDF(e.contractNumber)}
                      />
                    </div>
                  </>
                );
              })}
            </section>
          </section>
        </>
      ) : (
        <div>{labels?.documents?.noContracts}</div>
      )}
    </div>
  );
};

export default Contracts;
