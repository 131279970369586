import { useEffect, useState } from "react";

const InputField = ({
  type,
  label,
  inputClass,
  wrapperClassName,
  percentage,
  labelClass,
  change,
  blur,
  placeholder,
  value,
  min,
  max,
  name,
  id,
  step,
  error,
  touched,
  decimal,
  ariaLabel,
  errorClassName,
}) => {
  const [inputState, setInputState] = useState(value);

  function onChange(e) {
    setInputState(e.target.value);
    change(e.target.value);
  }
  function onBlur(e) {
    if (percentage) {
      let value = parseFloat(e.target.value).toFixed(2);
      value = value === "NaN" ? "0.00" : value;
      if (value > parseFloat(max)) {
        value = parseFloat(max).toFixed(2);
      } else if (value < parseFloat(min) && value > 0) {
        value = parseFloat(min).toFixed(2);
      }

      if (decimal) value = parseFloat(value).toFixed(2);
      else value = parseInt(value).toFixed(2);

      setInputState(value);
      blur(value);
    } else {
      blur(e.target.value);
    }
  }
  useEffect(() => {
    if (percentage) {
      setInputState(parseFloat(value).toFixed(2));
    }
  }, []);

  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label className={`input-label ${labelClass}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={`form-control ${inputClass} ${
          error && touched && "is-invalid"
        }`}
        placeholder={placeholder}
        name={name}
        id={id}
        value={inputState}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        max={max}
        step={step}
        aria-label={ariaLabel}
      />
      {percentage && "%"}
      {error && touched && (
        <span className={`error ${errorClassName}`}>{error}</span>
      )}
    </div>
  );
};

export default InputField;

InputField.defaultProps = {
  type: "text",
  change: () => {},
  blur: () => {},
  step: 1,
  labelClass: "mb-1",
  wrapperClassName: "",
  inputClass: "",
  errorClassName: "",
};
