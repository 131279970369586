export const local = {
  serviceURL: "http://localhost:8090",
  clientCustomizationURL: "http://localhost:8000",
};
export const dev = {
  serviceURL: "https://ricc-services-dev.ssnc-corp.cloud",
  clientCustomizationURL: "https://ricc-ui-client-dev.ssnc-corp.cloud",
};
export const qat = {
  serviceURL: "https://ricc-services-qat.ssnc-corp.cloud",
  clientCustomizationURL: "https://ricc-ui-client-qat.ssnc-corp.cloud",
};
export const cat = {
  serviceURL: "https://uat-apis.ssnc.cloud/ricc-services-uat",
  clientCustomizationURL: "https://ricc-ui-client-cat.ssnc.cloud",
};
export const prod = {
  serviceURL: "https://apis.ssnc.cloud/ricc-services",
  clientCustomizationURL: "https://ricc-ui-client.ssnc.cloud",
};
