import SectionOne from "components/Dashboard/SectionOne";
import SectionTwo from "components/Dashboard/SectionTwo";
import SectionThree from "components/Dashboard/SectionThree";
import SectionFooter from "common/Footer/SectionFooter";
import { createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAllocationsInStore } from "store/changeAllocationSlice";
import {
  useGetAllocationsQuery,
  useGetContractStatusQuery,
  useGetBalancesQuery,
  useGetTaxTypesQuery,
} from "service/APIService";
import labels from "utils/LocalizedStrings";
import Spinner from "common/Spinner/Spinner";
import { getRequiredIds } from "utils/Utilities";
import {
  clearAnnuityPayloadInStore,
  setAllocationsInStore,
  setSelectedTaxTypeInStore,
  setTaxTypesInStore,
  setAnnuityStatusInStore,
  setBalancesInStore,
} from "store/commonSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import cloneDeep from "lodash/cloneDeep";

export const DashboardContext = createContext({});

const Dashboard = () => {
  const dispatch = useDispatch();
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const taxTypes = useSelector(
    (state) => state?.commonReducer?.taxTypesInStore
  );

  const [selectedTaxType, setSelectedTaxType] = useState("");
  const [skipBalances, setSkipBalances] = useState(skipToken);
  const [skipAllocations, setSkipAllocations] = useState(skipToken);
  const [skipAnnuityStatus, setSkipAnnuityStatus] = useState(skipToken);

  const { data: taxTypesArray, isFetching: loading } = useGetTaxTypesQuery(
    getRequiredIds(participantDetails)
  );
  const { data: balances, isFetching: loading1 } =
    useGetBalancesQuery(skipBalances);
  const { data: allocations, isFetching: loading2 } =
    useGetAllocationsQuery(skipAllocations);
  const { data: contractStatus } = useGetContractStatusQuery(skipAnnuityStatus);

  useEffect(() => {
    dispatch(clearAllocationsInStore());
    dispatch(clearAnnuityPayloadInStore());
    sessionStorage.removeItem("image");
  }, []);

  useEffect(() => {
    let array = cloneDeep(taxTypesArray);
    if (array && array.length) {
      array.forEach((e) => {
        e.taxTypeName = labels?.dashboard?.[e.taxTypeName];
      });
      dispatch(setTaxTypesInStore([...array]));
      setSelectedTaxType(array[0]?.taxTypeCode);
    }
  }, [taxTypesArray]);

  useEffect(() => {
    if (selectedTaxType) {
      setSkipBalances(getRequiredIds(participantDetails, selectedTaxType));
      dispatch(setSelectedTaxTypeInStore(selectedTaxType));
      setSkipAnnuityStatus(getRequiredIds(participantDetails, selectedTaxType));
    }
  }, [selectedTaxType]);

  useEffect(() => {
    if (balances?.contractId || balances?.contractId === null) {
      setSkipAllocations(getRequiredIds(participantDetails, selectedTaxType));
      dispatch(setBalancesInStore(balances));
    }
  }, [balances]);

  useEffect(() => {
    if (allocations) {
      dispatch(setAllocationsInStore(allocations));
    }
  }, [allocations]);

  useEffect(() => {
    if (contractStatus) {
      dispatch(setAnnuityStatusInStore(contractStatus));
    }
  }, [contractStatus]);

  return (
    <DashboardContext.Provider
      value={{
        taxTypes,
        selectedTaxType,
        setSelectedTaxType,
        participantDetails,
        balances,
        allocations,
        contractStatus,
      }}
    >
      <main id="dashboard">
        {(loading || loading1 || loading2) && <Spinner />}
        <section className="dashboard-layout shadow-lg row py-4">
          <SectionOne />
          <SectionTwo />
          <SectionThree />
        </section>
        <SectionFooter page="dashboard" />
      </main>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
