import InfoPopup from "common/InfoPopup/InfoPopup";
import { amountFormatter } from "utils/Utilities";

const CurrencyBox = ({
  wrapperClassName,
  label,
  popoverText,
  amount,
  date,
}) => {
  return (
    <section className={`currency-box ${wrapperClassName}`}>
      <div>
        <div className="cash-value">
          {label}
          <InfoPopup position={"top"} content={popoverText} />
        </div>
        <div className="amount">{amountFormatter(amount)}</div>
        {date && <div className="date">As of {date}</div>}
      </div>
    </section>
  );
};

export default CurrencyBox;
CurrencyBox.defaultProps = {
  wrapperClassName: "",
};
