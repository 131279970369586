import useEventTracker from "common/Tracker/EventTracker";

const SelectDropdown = ({
  label,
  dropdownClass,
  wrapperClassName,
  selectClassName,
  labelClass,
  dropdownData,
  value,
  select,
  optionId,
  optionLabel,
  disabled,
  ariaLabel,
  defaultOption,
}) => {
  const gaEventTracker = useEventTracker({});
  function selectedValue(e) {
    gaEventTracker("Dropdown", `Selected ${e.target.value}`, ariaLabel);
    select(e.target.value);
  }
  return (
    <div className={`form-group selectDropdown ${wrapperClassName}`}>
      {label ? <label className={`${labelClass} mb-1`}>{label}</label> : ""}
      <div className={`${dropdownClass}`}>
        <select
          className={`form-control form-select ${
            selectClassName ? selectClassName : "w-auto"
          }`}
          onChange={selectedValue}
          value={value}
          disabled={disabled}
          aria-label={ariaLabel}
        >
          {defaultOption && <option value=""></option>}
          {dropdownData.map((e, index) => {
            return (
              <option
                value={optionId || e.id ? e[optionId] || e.id : e}
                key={e[optionId] || index}
              >
                {optionLabel || e.name ? e[optionLabel] || e.name : e}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectDropdown;

SelectDropdown.defaultProps = {
  dropdownData: [],
  dropdownClass: "",
  selectClassName: "",
  wrapperClassName: "",
  defaultOption: false,
};
