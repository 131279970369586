import { object, string } from "yup";

const validationSchema = object({
  firstName: string()
    .min(1, "First Name should be min 1 character")
    .required("First Name is required"),
  lastName: string()
    .min(1, "Last Name should be min 1 character")
    .required("Last Name is required"),
  fullName: string()
    .min(1, "Full Name should be min 1 character")
    .required("Full Name is required"),
  taxId: string().length(9, "SSN must be 9 digits").required("SSN is required"),
  // dateOfBirth: date().min(new Date(), "Date is not correct"),
});

export default validationSchema;
