import Button from "common/Button/Button";

const NavigationButtons = ({
  leftLabel,
  rightLabel,
  leftClick,
  rightClick,
  leftDisabled,
  rightDisabled,
  leftClassName,
  rightClassName,
  wrapperClassName,
}) => {
  return (
    <section
      className={`section-4 d-flex justify-content-end py-3 ${wrapperClassName}`}
    >
      <Button
        wrapperClassName={`col-xl-2 col-md-3 me-2 ${leftClassName}`}
        type="secondary"
        text={leftLabel}
        click={leftClick}
        disabled={leftDisabled}
        ariaLabel={leftLabel}
      />
      <Button
        wrapperClassName={`col-xl-2 col-md-3 ${rightClassName}`}
        type="primary"
        text={rightLabel}
        click={rightClick}
        disabled={rightDisabled}
        ariaLabel={rightLabel}
      />
    </section>
  );
};

export default NavigationButtons;
