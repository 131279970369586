import { useState, useEffect } from "react";
import dayjs from "dayjs";
import useEventTracker from "common/Tracker/EventTracker";

const Datepicker = ({
  id,
  name,
  label,
  wrapperClassName,
  change,
  blur,
  input,
  inputClass,
  min,
  max,
  value,
  error,
  touched,
}) => {
  const gaEventTracker = useEventTracker({});
  const [selectedDate, setSelectedDate] = useState(value);

  const onChange = (e) => {
    setSelectedDate(e.target.value);
    change(e.target.value);
  };

  const onBlur = (e) => {
    if (!dayjs(e.target.value, "YYYY-MM-DD", true).isValid()) {
      window.document.getElementById(id).value = "";
      blur("");
      setSelectedDate("");
      return;
    }

    if (!(dayjs(e.target.value).year() > 1900)) {
      window.document.getElementById(id).value = "";
      blur("");
      setSelectedDate("");
      return;
    }
    gaEventTracker("Datepicker", `Selected date - ${e.target.value}`, label);
    blur(e.target.value);
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(value.replaceAll("/", "-"));
    }
  }, []);

  return (
    <div className={`${wrapperClassName}`}>
      <label htmlFor={id} className="mb-1">
        {label}
      </label>

      <input
        className={`form-control ${inputClass} ${
          error && touched && "is-invalid"
        }`}
        type="date"
        id={id}
        name={name}
        value={selectedDate || ""}
        onChange={onChange}
        onBlur={onBlur}
        max={max}
        min={min}
        placeholder="MM/DD/YYYY"
      />
      {error && touched && <span className="error">{error}</span>}
    </div>
  );
};

export default Datepicker;
Datepicker.defaultProps = {
  change: () => {},
  blur: () => {},
  input: () => {},
  wrapperClassName: "",
  inputClass: "",
};
