import { useNavigate } from "react-router-dom";
import { Fragment, useContext, useState } from "react";
import RowHeader from "common/RowHeader/RowHeader";
import Row from "common/Row/Row";
import RowTable from "common/RowTable/RowTable";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import InputField from "common/InputField/InputField";
import SelectDropdown from "common/SelectDropdown/SelectDropdown";
import Button from "common/Button/Button";
import { getGender, maskSSN, percentageFormatter } from "utils/Utilities";
import { Formik, Form } from "formik";
import validationSchema from "./validationSchema";

const VerifyInformation = ({ from, context }) => {
  const navigate = useNavigate();
  const {
    labels,
    participantDetails,
    payload,
    allocationsInStore,
    updatePayload,
    staticData,
    getStateName,
    getCountryName,
    balancesInStore,
  } = useContext(context);
  const [editFields, setEditFields] = useState(false);

  const back = () => navigate(-1);
  function next() {
    if (from === "startIncome") {
      navigate("/start-income/3");
    } else {
      navigate("/take-annuity-out-of-plan/2");
    }
  }
  async function saveEditedFields() {
    if (payload?.address1Cached)
      await updatePayload(payload?.address1Cached, "address1");
    if (payload?.address2Cached)
      await updatePayload(payload?.address2Cached, "address2");
    if (payload?.address3Cached)
      await updatePayload(payload?.address3Cached, "address3");
    if (payload?.cityCached) await updatePayload(payload?.cityCached, "city");
    if (payload?.stateCodeCached)
      await updatePayload(payload?.stateCodeCached, "stateCode");
    if (payload?.zipCodeCached)
      await updatePayload(payload?.zipCodeCached, "zipCode");
    if (payload?.countryCodeCached)
      await updatePayload(payload?.countryCodeCached, "countryCode");
    if (payload?.emailCached)
      await updatePayload(payload?.emailCached, "email");
    if (payload?.mobileCached)
      await updatePayload(payload?.mobileCached, "mobile");
    setEditFields(!editFields);
  }

  const validateForm = async () => {
    const errors = {};
    try {
      await validationSchema.validate(payload, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((e) => {
        errors[e.path] = e.message;
        return;
      });
    }

    return errors;
  };

  return (
    <>
      <section className="section-one py-2 mb-3">
        <RowHeader header={labels?.[from]?.myInvestments} click={back} />
        <Row
          leftValue={labels?.[from]?.contractNumber}
          rightValue={balancesInStore?.contractId}
        />
        <Row
          leftValue={labels?.[from]?.contractIssueDate}
          rightValue={balancesInStore?.contractDate}
        />
        <Row
          leftValue={labels?.[from]?.product}
          rightValue={labels?.[from]?.productName}
        />
        <Row
          leftValue={labels?.[from]?.planType}
          rightValue={participantDetails?.planType}
        />
      </section>

      <Formik initialValues={{}} validate={validateForm} validateOnBlur={true}>
        {({ errors, touched, setFieldTouched }) => (
          <Form>
            <section className="section-two py-2 mb-3">
              <RowHeader
                header={labels?.[from]?.contactOwnerInfo}
                buttonLabel={!editFields ? labels?.[from]?.makeChanges : ""}
                click={() => setEditFields(true)}
              />
              <Row
                leftValue={labels?.[from]?.name}
                rightValue={
                  participantDetails?.firstName +
                  " " +
                  participantDetails?.middleName +
                  " " +
                  participantDetails?.lastName
                }
              />
              <div className="flex-between-center border-bottom-1 py-3">
                <div className="left-value address-left-value">
                  {labels?.[from]?.address}
                </div>
                {!editFields && (
                  <div className="right-value d-flex">
                    <>
                      {payload?.address1 +
                        ", " +
                        payload?.address2 +
                        (payload.address2 ? ", " : " ") +
                        payload?.address3 +
                        (payload.address3 ? ", " : " ") +
                        payload?.city +
                        ", " +
                        (payload.countryCode === "US"
                          ? getStateName(payload?.stateCode, staticData) + ", "
                          : "") +
                        getCountryName(payload?.countryCode, staticData) +
                        ", " +
                        payload?.zipCode}
                    </>
                  </div>
                )}
                {editFields && (
                  <div className="right-value addressFields">
                    <InputField
                      label={labels?.[from]?.addressLine1}
                      type="text"
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      labelClass="col-md-4 me-2"
                      id="addressLine1"
                      ariaLabel="Address Line 1"
                      value={payload?.address1}
                      blur={(val) => updatePayload(val, "address1Cached")}
                    />
                    <InputField
                      label={labels?.[from]?.addressLine2}
                      type="text"
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      labelClass="col-md-4 me-2"
                      id="addressLine2"
                      ariaLabel="Address Line 2"
                      value={payload?.address2}
                      blur={(val) => updatePayload(val, "address2Cached")}
                    />
                    <InputField
                      label={labels?.[from]?.addressLine3}
                      type="text"
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      labelClass="col-md-4 me-2"
                      id="addressLine3"
                      ariaLabel="Address Line 3"
                      value={payload?.address3}
                      blur={(val) => updatePayload(val, "address3Cached")}
                    />
                    <InputField
                      label={labels?.[from]?.city}
                      type="text"
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      labelClass="col-md-4 me-2"
                      id="city"
                      ariaLabel="City"
                      value={payload?.city}
                      blur={(val) => updatePayload(val, "cityCached")}
                    />
                    <SelectDropdown
                      label={labels?.[from]?.state}
                      labelClass="col-md-4"
                      ariaLabel="State"
                      dropdownData={staticData?.usStateList || []}
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      dropdownClass="ps-2"
                      selectClassName="w-100"
                      optionLabel="governmentUnitName"
                      optionId="shortCode"
                      select={(val) => updatePayload(val, "stateCodeCached")}
                      value={
                        payload.countryCode === "US" ? payload.stateCode : ""
                      }
                      disabled={payload.countryCode !== "US"}
                      defaultOption={true}
                    />
                    <InputField
                      label={labels?.[from]?.zipCode}
                      type="text"
                      placeholder="XXXXX-XXXX"
                      labelClass="col-md-4 me-2"
                      id="zipCode"
                      ariaLabel="Zip Code"
                      wrapperClassName="mb-2 d-flex align-items-baseline "
                      value={payload?.zipCode}
                      blur={(val) => updatePayload(val, "zipCodeCached")}
                    />
                    <SelectDropdown
                      label={labels?.[from]?.country}
                      labelClass="col-md-4"
                      ariaLabel="Country"
                      dropdownData={staticData?.countryList || []}
                      dropdownClass="ps-2"
                      wrapperClassName="mb-2 d-flex align-items-baseline"
                      selectClassName="w-100"
                      optionLabel="governmentUnitName"
                      optionId="shortCode"
                      select={(val) => updatePayload(val, "countryCode")}
                      value={payload.countryCode}
                    />
                  </div>
                )}
              </div>
              <Row
                leftValue={labels?.[from]?.ssnOrTin}
                rightValue={maskSSN(participantDetails?.ssn)}
              />
              <Row
                leftValue={labels?.[from]?.dateOfBirth}
                rightValue={participantDetails?.dob}
              />
              <Row
                leftValue={labels?.[from]?.gender}
                rightValue={getGender(participantDetails?.genderCode)}
              />

              <Row
                leftValue={labels?.[from]?.email}
                rightValue={editFields ? "" : payload?.email}
              >
                {editFields && (
                  <div className="right-value addressFields">
                    <InputField
                      type="email"
                      errorClassName="position-relative"
                      id="email"
                      name="emailCached"
                      ariaLabel="Email Id"
                      value={payload?.email}
                      blur={(val) => {
                        updatePayload(val, "emailCached");
                        setFieldTouched("emailCached", true);
                      }}
                      error={errors.emailCached}
                      touched={touched.emailCached}
                    />
                  </div>
                )}
              </Row>

              <Row
                leftValue={labels?.[from]?.phone}
                rightValue={!editFields ? payload?.mobile : ""}
              >
                {editFields && (
                  <div className="right-value addressFields">
                    <InputField
                      type="number"
                      errorClassName="position-relative"
                      id="mobile"
                      name="mobileCached"
                      ariaLabel="Mobile"
                      value={payload?.mobile}
                      blur={(val) => {
                        updatePayload(val, "mobileCached");
                        setFieldTouched("mobileCached", true);
                      }}
                      error={errors.mobileCached}
                      touched={touched.mobileCached}
                    />
                  </div>
                )}
              </Row>
            </section>

            {editFields && (
              <div className="d-flex justify-content-end">
                <Button
                  text={labels?.[from]?.cancel}
                  ariaLabel="Cancel"
                  type="secondary"
                  wrapperClassName="col-md-2 col-6 d-flex justify-content-end me-2"
                  click={() => {
                    setEditFields(!editFields);
                    setFieldTouched("emailCached", false);
                    setFieldTouched("mobileCached", false);
                  }}
                />
                <Button
                  text={labels?.[from]?.save}
                  ariaLabel="Save"
                  type="primary"
                  wrapperClassName="col-md-2 col-6 d-flex justify-content-end"
                  click={saveEditedFields}
                  disabled={errors?.emailCached || errors?.mobileCached}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>

      <RowTable title={labels?.[from]?.investmentAllocation}>
        <Fragment key="row-header">
          <span className="col-md-4">{labels?.[from]?.allocation}</span>
          <span className="col-md-4 text-end">
            {labels?.[from]?.percentage}
          </span>
        </Fragment>

        <Fragment key="row-body">
          {allocationsInStore?.allocationsInfoList?.map((e) => {
            return (
              <Fragment key={e.investmentVehicleId}>
                <span className="col-md-4">{e.isrInvestmentVehicleNm}</span>
                <span className="col-md-4 text-end">
                  {percentageFormatter(e.isrInvestmentVehicleCurAllocPctRt)}
                </span>
              </Fragment>
            );
          })}
        </Fragment>
      </RowTable>

      <NavigationButtons
        leftLabel={labels?.[from]?.back}
        leftClick={back}
        rightLabel={labels?.[from]?.next}
        rightClick={next}
        leftClassName="col-4"
        rightClassName="col-4"
      />
    </>
  );
};

export default VerifyInformation;
