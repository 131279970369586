import { Fragment, useContext, useState } from "react";
import Checkbox from "common/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { ChangeAllocationsContext } from "./ChangeAllocations";
import { percentageFormatterForDisplay } from "utils/Utilities";
import Row from "common/Row/Row";
import RowHeader from "common/RowHeader/RowHeader";
import RowTable from "common/RowTable/RowTable";
import NavigationButtons from "common/NavigationButtons/NavigationButtons";
import dayjs from "dayjs";

const ProvideInformation = () => {
  const navigate = useNavigate();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const { allocations, totalInvested, labels, submit, participantDetails } =
    useContext(ChangeAllocationsContext);

  const back = () => navigate(-1);
  const getEffectiveDate = (dob) => {
    const dobDate = dob.split("/");
    const currentDate = dayjs().format("MM/DD/YYYY").split("/");

    // Month is greater than current
    if (dobDate[0] > currentDate[0]) {
      return dobDate[0] + "/" + dobDate[1] + "/" + currentDate[2];
    }
    // Month is less than current
    else if (dobDate[0] < currentDate[0]) {
      return (
        dobDate[0] + "/" + dobDate[1] + "/" + (parseInt(currentDate[2]) + 1)
      );
    }
    // Month is same as current Month
    else {
      return dobDate[1] > currentDate[1]
        ? dobDate[0] + "/" + dobDate[1] + "/" + currentDate[2]
        : dobDate[0] + "/" + dobDate[1] + "/" + (parseInt(currentDate[2]) + 1);
    }
  };

  return (
    <section className="single-page-container">
      <RowHeader
        header={labels?.changeAnnuityAllocations?.myInvestments}
        buttonLabel={labels?.changeAnnuityAllocations?.makeChanges}
        click={back}
      />

      <Row
        leftValue={labels?.changeAnnuityAllocations?.action}
        rightValue={labels?.changeAnnuityAllocations?.futureInvestment}
      />
      <Row
        leftValue={labels?.changeAnnuityAllocations?.affects}
        rightValue={labels?.changeAnnuityAllocations?.currentBalances}
      />
      <Row
        leftValue={labels?.changeAnnuityAllocations?.rebalanceDate}
        rightValue={getEffectiveDate(participantDetails?.dob)}
      />

      <div className="pt-3 border-bottom-1">
        <RowTable
          description={labels?.changeAnnuityAllocations?.allFutureContributions}
          wrapperClassName="mx-5 mobile-investment-box"
        >
          <Fragment key="row-header">
            <span>{labels?.changeAnnuityAllocations?.investment}</span>
            <span>{labels?.changeAnnuityAllocations?.amount}</span>
          </Fragment>

          <Fragment key="row-body">
            {allocations
              .filter((e) => e.reqInvVehicleCurAllocPercent > 0)
              .map((e) => {
                return (
                  <div
                    key={e.isrInvestmentVehicleId}
                    className="d-flex justify-content-between w-100"
                  >
                    <span>{e.isrInvestmentVehicleName}</span>
                    <span>
                      {percentageFormatterForDisplay(
                        e.reqInvVehicleCurAllocPercent
                      )}
                    </span>
                  </div>
                );
              })}
          </Fragment>

          <Fragment key="row-total">
            <span>{labels?.changeAnnuityAllocations?.total}</span>
            <span>{percentageFormatterForDisplay(totalInvested)}</span>
          </Fragment>
        </RowTable>
      </div>

      <div className="py-4 border-bottom-1">
        <Checkbox
          id="terms"
          label={labels?.changeAnnuityAllocations?.acceptTerms}
          change={(val) => setAgreeToTerms(val)}
          terms={labels?.changeAnnuityAllocations?.terms}
          ariaLabel="Terms and Conditions"
        />

        <NavigationButtons
          leftLabel={labels?.changeAnnuityAllocations?.back}
          leftClick={back}
          rightLabel={labels?.changeAnnuityAllocations?.submit}
          rightClick={submit}
          rightDisabled={!agreeToTerms}
          leftClassName="col-4"
          rightClassName="col-4"
        />
      </div>
    </section>
  );
};

export default ProvideInformation;
