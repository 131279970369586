import { Link } from "react-router-dom";
import MaterialIcon from "common/MaterialIcon/MaterialIcon";
import sanitize from "utils/SanitizeHtml";
import useEventTracker from "common/Tracker/EventTracker";

const ArrowLink = ({
  id,
  link,
  text,
  path,
  className,
  description,
  wrapperClassName,
  disabled,
  iconClassName,
  children,
}) => {
  const gaEventTracker = useEventTracker({});
  return (
    <div className={`${wrapperClassName} ${disabled ? "disable" : ""}`} id={id}>
      <div className={`d-flex align-items-center ${className}`}>
        <MaterialIcon
          iconName="arrow_forward"
          iconClassName={`icon ${iconClassName}`}
        />
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        )}
        {path && (
          <>
            <Link
              to={path}
              onClick={() =>
                gaEventTracker("Arrow Link", `Clicked on ${text}`, text)
              }
            >
              {text}
            </Link>
            {children}
          </>
        )}
      </div>
      {description && (
        <small
          className="ps-4 d-block"
          dangerouslySetInnerHTML={sanitize(description)}
        ></small>
      )}
    </div>
  );
};

export default ArrowLink;
ArrowLink.defaultProps = {
  wrapperClassName: "",
  className: "",
};
