import useEventTracker from "common/Tracker/EventTracker";

const Checkbox = ({
  wrapperClassName,
  checkboxClassName,
  id,
  name,
  label,
  change,
  terms,
  value,
  ariaLabel,
}) => {
  const gaEventTracker = useEventTracker({});
  function onChange(e) {
    gaEventTracker(
      "Checkbox",
      `User clicked on ${ariaLabel} checkbox with value ${e.target.checked}`,
      ariaLabel
    );
    change(e.target.checked);
  }
  return (
    <>
      <div className={`checkbox-component form-check ${wrapperClassName}`}>
        <input
          className={`form-check-input ${checkboxClassName}`}
          type="checkbox"
          value=""
          id={id}
          name={name}
          onChange={onChange}
          checked={value}
          aria-label={ariaLabel}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
      {terms && <p className="py-3 terms">{terms}</p>}
    </>
  );
};

export default Checkbox;
Checkbox.defaultProps = {
  change: () => {},
  wrapperClassName: "",
  checkboxClassName: "",
};
